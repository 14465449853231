import './bg.css'
import BGHeader from "./components/BGHeader";
import Blog from "../Home/components/Blog"
import ServiceBlog from "../Services/components/Blog"
import CategoryBlog from '../Home/components/CategoryBlog'

const Home=()=>{
	return(
		<>
		<BGHeader/>
		<ServiceBlog />
		<CategoryBlog/>
		<Blog/>
		</>
		)
}
export default Home