import React from "react";
import { Link } from "react-router-dom";
import image24 from "../../../Assets/images/image24.png"
import image25 from "../../../Assets/images/image25.png"
import logo from '../../../Assets/logo/Tilosh.png';
const Header =()=>{
	return (
		<>
		<div className="Header">
		<div className="left__header">
		<Link to="/" className="header_link"><img src={logo} className="logo__header" alt="logo" /></Link>
		<Link to="/vendor" className="header_link"><div className="menu__left">አቅራቢዎች</div></Link>
		<Link to="/about" className="header_link"><div className="menu__left">ስለጥሎሽ</div></Link>
		</div>
		<div className="right__header">
		<Link to="/signup" className="header_link"><div className="menu__right vendor">አቅራቢ ነዎት?</div></Link>
		<Link to="/login" className="header_link"><div className="menu__right">መግቢያ</div></Link>
		<Link to="/bg/signup" className="header_link"><div className="menu__right menu__button">
		<span className="menu__text">
                          {"አሁኑኑ ይጀመሩ"}
        </span>
        </div></Link>

	 <Link to="/" className="header_link">	<div className="menu__right menu__lang">
    
            <div className="translate">  
            <div className="translate__icon">
            </div></div>
             <span className="menu__right">አማ</span>
             </div></Link>
		</div>
		</div>
		<div className="container">
		 <div className="group-1-5">
              <div className="vector-1-1584">
                <div className="nodeBg-I19043415_1762592"></div>
              </div>
              <div className="vector-2-530">
                <div className="nodeBg-I19043415_1762593"></div>
              </div>
            </div>

            <div className="group-2-3416">
              <div className="vector-1-3234">
                <div className="nodeBg-I19043415_1765440"></div>
              </div>
              <div className="vector-2-3783">
                <div className="nodeBg-I19043415_1765441"></div>
              </div>
            </div>
            <div className="group-3-2303">
              <div className="vector-1-540">
                <div className="nodeBg-I19043415_1765449"></div>
              </div>
              <div className="vector-2-3612">
                <div className="nodeBg-I19043415_1765450"></div>
              </div>
            </div>
            <div className="group-4-1638">
              <div className="vector-1-851">
                <div className="nodeBg-I19043415_1765443"></div>
              </div>
              <div className="vector-2-216">
                <div className="nodeBg-I19043415_1765444"></div>
              </div>
            </div>
          <div className="frame-h">
          <div className="image-1-h">
                <div className="rectangle-1-1-h">
                  <div className="nodeBg-I19043465_18634231">
                    {" "}
                  </div>
                </div>
                <div className="rectangle-2-1-148">
                  <div className="nodeBg-I19043465_18634232">
                    {" "}
                  </div>
                </div>
              </div>
              <div className="content-1-178">
                <div className="frame-10">
                  <div className="heading-1">
                    <span className="heading-1-2856-0">
                      {"ሰርግዎትን በቀላሉ ይደግሱ "}
                    </span>
                  </div>
                  <div className="supporting-text-1-656">
                    <span className="supporting-text-1-656-0">
                      {
                        "በከተማ ውስጥ ያሉ ምርጥ የፕሮግራምና የሰርግ አዘጋጆችና አቅራቢዎችን ያግኙ።"
                      }
                    </span>
                  </div>
                </div>
                <div className="search_bar">
                  <div className="input-field-1-2254">
                  <div className="input-1-2695">
                  <div className="dropdown-1-472">
                          <div className="currencydollar-1-2436"></div>
                        </div>
                        <div className="content-1-1881">
                          <div className="text-input-1-923">
                            <div className="leading-text-1-312">
                              <span className="leading-text-1-312-0">
                                {"ብር"}
                              </span>
                            </div>
                            <div className="text-1-2952">
                              <span contenteditable="true" className="text-1-2952-0">
                                {"የገንዘብ መጠን ያስገቡ"}
                              </span>
                            </div>
                            </div>
                          </div>
                          </div>
                          </div>
                  <div className="input-field-2-476">
                      <div className="input-with-labe-1-893">
                      <div className="input-1-610">
                        <div className="content-1-903">
                          <div className="text-input-1-595">
                            <div className="text-1-957">
                              <span className="text-1-957-0">{"አቅራቢዎች"}</span>
                            </div>
                            </div>
                            </div>
                        <div className="dropdown-1-1353">
                            <div className="icon-1-3834">
                              <div className="nodeBg-I19043415_1751641_3285380151_3463404534"></div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                  <div className="buttonsbutton-1-3526">
                      <div className="text-1-h">
                        <span className="text-1-4620-0">{"ፈልግ"}</span>
                      </div>
                    </div>
                    </div>
                  </div>
                  </div>
		</div>
		</>
		)
}
export default Header;