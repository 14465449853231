import React, { useState } from 'react';
import {login} from '../api/user'
import { useNavigate } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
import sheraton from "../../../Assets/images/Footer2.png"
import { Link } from "react-router-dom";
const Login=()=>{
let navigate = useNavigate(); 
const [email,setEmail]=useState('')
const [password,setPassword]=useState('')
const [error,setError]=useState('')
const loginUser=async()=>{
	if(email==''||password==''){
		setError('Please enter email and password')
		return
	}
const user={email:email,password:password}

const data= await login(user)
if(Object.keys(data).length !== 0)
{
	localStorage.setItem('Tuser', JSON.stringify(data["data"]["user"]));
  if(data["data"]["user"]['userType']=='BG001')
  {
  	let path = `/bg`; 
    navigate(path);
  }else{
  	let path = `/home`; 
    navigate(path);
  }
}else{
	setError('Invalid email or password')
}
}
const handleEmail=(value)=>{
setError('')
setEmail(value)
}
const handlePassword=(value)=>{
	setError('')
	setPassword(value)
}
	return(
		<div className="signup_continer">
		<div className="signup_modal">
		<div className="signup_left_modal">
		<img src={logo} className="vendor_logo__header" alt="logo" />
		<div className="signup_form">
		<div className="form_title">
		Welcome Back
		</div>
		<div className="input_fields">
		<span>Email</span>
		<input 
		value={email}
    onChange={(e)=>handleEmail(e.target.value)}
    type="email" className="input_field"/>
		</div>
		<div className="input_fields">
		<span>Password</span>
		<input
		value={password}
    onChange={(e)=>handlePassword(e.target.value)} 
    type="password" className="input_field"/>
		</div>
		<div onClick={loginUser} className="signup_button">Login</div>
		<span>{error}</span>
		</div>
		<Link to="/signup" className="header_link"><div className="signup_login">New here? Signup</div></Link>
		</div>
		<div className="signup_right_modal">
		<div className="image-1-19-su">
           <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${sheraton})` }}>
                        {" "}
            </div>
        </div>
		</div>
		</div>
		</div>
		)
}
export default Login