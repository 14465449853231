import React from "react";
import { Link } from "react-router-dom";
// import "./style.css";
import "./animation.css";
const Home = () => {
  return (
    <div className="parent-div">
      <div className="frame-8-1-3996" id="id-19043399">
        <div className="hint-text1-0-35" id="id-112882">
          <span className="hint-text1-0-35-0">
            {"This is a hint text to help user."}
          </span>
        </div>
        <div className="placeholder1-0-968" id="id-14283">
          <div className="icon-1-915" id="id-I14283_3465410041">
            <div
              className="nodeBg-I14283_3465410041"
              id="id-bg-I14283_3465410041"
            ></div>
          </div>
        </div>
        <div className="group1-0-840" id="id-1765725">
          <div className="vector-1-1575" id="id-1765726">
            <div className="nodeBg-1765726" id="id-bg-1765726"></div>
          </div>
          <div className="vector-2-427" id="id-1765727">
            <div className="nodeBg-1765727" id="id-bg-1765727"></div>
          </div>
        </div>



        
        <div className="blog-section-1-280" id="id-19043416">
          <div className="container-1-4026" id="id-19043417">
            <div className="content-1-1587" id="id-19043418">
              <div className="heading-and-sup-1-1190" id="id-19043419">
                <div className="supporting-text-1-4998" id="id-19043423">
                  <span className="supporting-text-1-4998-0">
                    {
                      "Tool and strategies modern teams need to help their companies grow."
                    }
                  </span>
                </div>
                <div className="heading-and-sub-1-1138" id="id-19043420">
                  <div className="subheading-1-1350" id="id-19043421">
                    <span className="subheading-1-1350-0">{"Our blog"}</span>
                  </div>
                  <div className="heading-1-208" id="id-19043422">
                    <span className="heading-1-208-0">
                      {"Top recent programs"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-2-2220" id="id-19043424">
            <div className="content-1-2014" id="id-19043425">
              <div className="blog-post-card-1-252" id="id-19043426">
                <div className="image-1-2550" id="id-I19043426_16837556">
                  <div
                    className="nodeBg-I19043426_16837556"
                    id="id-bg-I19043426_16837556"
                  >
                    {" "}
                  </div>
                  <div className="rate-1-3315" id="id-I19043426_16940059">
                    <div className="text-1-3066" id="id-I19043426_16938781">
                      <div
                        className="this-is-a-text-1-3188"
                        id="id-I19043426_16938781_123344529"
                      >
                        <span className="this-is-a-text-1-3188-0">
                          {"16 reviews"}
                        </span>
                      </div>
                    </div>
                    <div className="frame-5-1-833" id="id-I19043426_16940129">
                      <div className="text-1-66" id="id-I19043426_16940065">
                        <div
                          className="this-is-a-text-1-106"
                          id="id-I19043426_16940065_123344529"
                        >
                          <span className="this-is-a-text-1-106-0">
                            {"4.5"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="rate-star-1-2401"
                        id="id-I19043426_16940060"
                      >
                        <div
                          className="icon--starfille-1-833"
                          id="id-I19043426_16940060_39611242"
                        >
                          <div
                            className="vector-1-2056"
                            id="id-I19043426_16940060_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043426_16940060_39611242_35172"
                              id="id-bg-I19043426_16940060_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-2-2511"
                        id="id-I19043426_16940061"
                      >
                        <div
                          className="icon--starfille-1-350"
                          id="id-I19043426_16940061_39611242"
                        >
                          <div
                            className="vector-1-4368"
                            id="id-I19043426_16940061_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043426_16940061_39611242_35172"
                              id="id-bg-I19043426_16940061_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-3-400"
                        id="id-I19043426_16940062"
                      >
                        <div
                          className="icon--starfille-1-4818"
                          id="id-I19043426_16940062_39611242"
                        >
                          <div
                            className="vector-1-3696"
                            id="id-I19043426_16940062_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043426_16940062_39611242_35172"
                              id="id-bg-I19043426_16940062_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-4-378"
                        id="id-I19043426_16940063"
                      >
                        <div
                          className="icon--starfille-1-99"
                          id="id-I19043426_16940063_39611242"
                        >
                          <div
                            className="vector-1-822"
                            id="id-I19043426_16940063_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043426_16940063_39611242_35172"
                              id="id-bg-I19043426_16940063_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-5-624"
                        id="id-I19043426_16940064"
                      >
                        <div
                          className="mask-group-1-4361"
                          id="id-I19043426_16940064_39611239"
                        >
                          <div
                            className="nodeBg-I19043426_16940064_39611239"
                            id="id-bg-I19043426_16940064_39611239"
                          >
                            {" "}
                          </div>
                        </div>
                        <div
                          className="icon--starfille-1-1972"
                          id="id-I19043426_16940064_39611244"
                        >
                          <div
                            className="vector-1-1134"
                            id="id-I19043426_16940064_39611244_35172"
                          >
                            <div
                              className="nodeBg-I19043426_16940064_39611244_35172"
                              id="id-bg-I19043426_16940064_39611244_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-1-2742" id="id-I19043426_16837557">
                  <div className="categories-1-168" id="id-I19043426_16837565">
                    <div className="badge-1-68" id="id-I19043426_16837566">
                      <div
                        className="text-1-1873"
                        id="id-I19043426_16837566_4825411748"
                      >
                        <span className="text-1-1873-0">{"Venue"}</span>
                      </div>
                    </div>
                    <div className="badge-2-396" id="id-I19043426_16837567">
                      <div
                        className="text-1-672"
                        id="id-I19043426_16837567_4825411748"
                      >
                        <span className="text-1-672-0">{"Cake"}</span>
                      </div>
                    </div>
                    <div className="badge-3-1504" id="id-I19043426_16837568">
                      <div
                        className="text-1-2800"
                        id="id-I19043426_16837568_4825411748"
                      >
                        <span className="text-1-2800-0">{"Photo&Video"}</span>
                      </div>
                    </div>
                    <div className="badge-4-1155" id="id-I19043426_16837573">
                      <div
                        className="text-1-361"
                        id="id-I19043426_16837573_4825411748"
                      >
                        <span className="text-1-361-0">{"Beauty"}</span>
                      </div>
                    </div>
                    <div className="badge-5-152" id="id-I19043426_16837672">
                      <div
                        className="text-1-3456"
                        id="id-I19043426_16837672_4825411748"
                      >
                        <span className="text-1-3456-0">{"Catering"}</span>
                      </div>
                    </div>
                    <div className="badge-6-20" id="id-I19043426_16837694">
                      <div
                        className="text-1-558"
                        id="id-I19043426_16837694_4825411748"
                      >
                        <span className="text-1-558-0">{"DJ"}</span>
                      </div>
                    </div>
                    <div className="badge-7-249" id="id-I19043426_16837720">
                      <div
                        className="text-1-1144"
                        id="id-I19043426_16837720_4825411748"
                      >
                        <span className="text-1-1144-0">{"Decor"}</span>
                      </div>
                    </div>
                    <div className="badge-8-2310" id="id-I19043426_16837750">
                      <div
                        className="text-1-251"
                        id="id-I19043426_16837750_4825411748"
                      >
                        <span className="text-1-251-0">{"Jewellery"}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="heading-and-tex-1-2542"
                    id="id-I19043426_16837558"
                  >
                    <div
                      className="olivia-rhye--20-1-2776"
                      id="id-I19043426_16837559"
                    >
                      <span className="olivia-rhye--20-1-2776-0">
                        {"Sheraton Addis • Addis Ababa"}
                      </span>
                    </div>
                    <div
                      className="supporting-text-1-3599"
                      id="id-I19043426_16837564"
                    >
                      <span className="supporting-text-1-3599-0">
                        {
                          "How do you create compelling presentations that wow your colleagues and impress your managers?"
                        }
                      </span>
                    </div>
                    <div
                      className="heading-and-ico-1-4900"
                      id="id-I19043426_16837560"
                    >
                      <div
                        className="heading-1-4692"
                        id="id-I19043426_16837561"
                      >
                        <span className="heading-1-4692-0">
                          {"Chala & Bezi"}
                        </span>
                      </div>
                      <div
                        className="icon-wrap-1-1368"
                        id="id-I19043426_16837562"
                      >
                        <div
                          className="arrowupright-1-363"
                          id="id-I19043426_16837563"
                        >
                          <div
                            className="icon-1-3724"
                            id="id-I19043426_16837563_3463404516"
                          >
                            <div
                              className="nodeBg-I19043426_16837563_3463404516"
                              id="id-bg-I19043426_16837563_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-2-462" id="id-19043427">
                <div className="image-1-1230" id="id-I19043427_16837556">
                  <div
                    className="nodeBg-I19043427_16837556"
                    id="id-bg-I19043427_16837556"
                  >
                    {" "}
                  </div>
                  <div className="rate-1-375" id="id-I19043427_16940059">
                    <div className="text-1-609" id="id-I19043427_16938781">
                      <div
                        className="this-is-a-text-1-429"
                        id="id-I19043427_16938781_123344529"
                      >
                        <span className="this-is-a-text-1-429-0">
                          {"16 reviews"}
                        </span>
                      </div>
                    </div>
                    <div className="frame-5-1-1740" id="id-I19043427_16940129">
                      <div className="text-1-3230" id="id-I19043427_16940065">
                        <div
                          className="this-is-a-text-1-784"
                          id="id-I19043427_16940065_123344529"
                        >
                          <span className="this-is-a-text-1-784-0">
                            {"4.5"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="rate-star-1-2279"
                        id="id-I19043427_16940060"
                      >
                        <div
                          className="icon--starfille-1-42"
                          id="id-I19043427_16940060_39611242"
                        >
                          <div
                            className="vector-1-456"
                            id="id-I19043427_16940060_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043427_16940060_39611242_35172"
                              id="id-bg-I19043427_16940060_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-star-2-0" id="id-I19043427_16940061">
                        <div
                          className="icon--starfille-1-2349"
                          id="id-I19043427_16940061_39611242"
                        >
                          <div
                            className="vector-1-1406"
                            id="id-I19043427_16940061_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043427_16940061_39611242_35172"
                              id="id-bg-I19043427_16940061_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-3-3717"
                        id="id-I19043427_16940062"
                      >
                        <div
                          className="icon--starfille-1-244"
                          id="id-I19043427_16940062_39611242"
                        >
                          <div
                            className="vector-1-90"
                            id="id-I19043427_16940062_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043427_16940062_39611242_35172"
                              id="id-bg-I19043427_16940062_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-4-2520"
                        id="id-I19043427_16940063"
                      >
                        <div
                          className="mask-group-1-360"
                          id="id-I19043427_16940063_39611239"
                        >
                          <div
                            className="nodeBg-I19043427_16940063_39611239"
                            id="id-bg-I19043427_16940063_39611239"
                          >
                            {" "}
                          </div>
                        </div>
                        <div
                          className="icon--starfille-1-2688"
                          id="id-I19043427_16940063_39611244"
                        >
                          <div
                            className="vector-1-60"
                            id="id-I19043427_16940063_39611244_35172"
                          >
                            <div
                              className="nodeBg-I19043427_16940063_39611244_35172"
                              id="id-bg-I19043427_16940063_39611244_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-5-3600"
                        id="id-I19043427_16940064"
                      >
                        <div
                          className="icon--starfille-1-3942"
                          id="id-I19043427_16940064_39611236"
                        >
                          <div
                            className="vector-1-2808"
                            id="id-I19043427_16940064_39611236_35172"
                          >
                            <div
                              className="nodeBg-I19043427_16940064_39611236_35172"
                              id="id-bg-I19043427_16940064_39611236_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-1-1468" id="id-I19043427_16837557">
                  <div className="categories-1-2668" id="id-I19043427_16837565">
                    <div className="badge-1-628" id="id-I19043427_16837566">
                      <div
                        className="text-1-116"
                        id="id-I19043427_16837566_4825411748"
                      >
                        <span className="text-1-116-0">{"Jewellers"}</span>
                      </div>
                    </div>
                    <div className="badge-2-1348" id="id-I19043427_16837567">
                      <div
                        className="text-1-102"
                        id="id-I19043427_16837567_4825411748"
                      >
                        <span className="text-1-102-0">{"Cake"}</span>
                      </div>
                    </div>
                    <div className="badge-3-3626" id="id-I19043427_16837568">
                      <div
                        className="text-1-1036"
                        id="id-I19043427_16837568_4825411748"
                      >
                        <span className="text-1-1036-0">{"Photo&Video"}</span>
                      </div>
                    </div>
                    <div className="badge-4-372" id="id-I19043427_16837573">
                      <div
                        className="text-1-1232"
                        id="id-I19043427_16837573_4825411748"
                      >
                        <span className="text-1-1232-0">{"Beauty"}</span>
                      </div>
                    </div>
                    <div className="badge-5-540" id="id-I19043427_16837672">
                      <div
                        className="text-1-2254"
                        id="id-I19043427_16837672_4825411748"
                      >
                        <span className="text-1-2254-0">{"Catering"}</span>
                      </div>
                    </div>
                    <div className="badge-6-1134" id="id-I19043427_16837694">
                      <div
                        className="text-1-860"
                        id="id-I19043427_16837694_4825411748"
                      >
                        <span className="text-1-860-0">{"DJ"}</span>
                      </div>
                    </div>
                    <div className="badge-7-1886" id="id-I19043427_16837720">
                      <div
                        className="text-1-1598"
                        id="id-I19043427_16837720_4825411748"
                      >
                        <span className="text-1-1598-0">{"Decor"}</span>
                      </div>
                    </div>
                    <div className="badge-8-3213" id="id-I19043427_16837750">
                      <div
                        className="text-1-1890"
                        id="id-I19043427_16837750_4825411748"
                      >
                        <span className="text-1-1890-0">{"Jewellery"}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="heading-and-tex-1-776"
                    id="id-I19043427_16837558"
                  >
                    <div
                      className="olivia-rhye--20-1-700"
                      id="id-I19043427_16837559"
                    >
                      <span className="olivia-rhye--20-1-700-0">
                        {"Sheraton Addis • Addis Ababa"}
                      </span>
                    </div>
                    <div
                      className="supporting-text-1-648"
                      id="id-I19043427_16837564"
                    >
                      <span className="supporting-text-1-648-0">
                        {
                          "How do you create compelling presentations that wow your colleagues and impress your managers?"
                        }
                      </span>
                    </div>
                    <div
                      className="heading-and-ico-1-2970"
                      id="id-I19043427_16837560"
                    >
                      <div
                        className="heading-1-1205"
                        id="id-I19043427_16837561"
                      >
                        <span className="heading-1-1205-0">
                          {"Abel & Makeda"}
                        </span>
                      </div>
                      <div
                        className="icon-wrap-1-525"
                        id="id-I19043427_16837562"
                      >
                        <div
                          className="arrowupright-1-3432"
                          id="id-I19043427_16837563"
                        >
                          <div
                            className="icon-1-4895"
                            id="id-I19043427_16837563_3463404516"
                          >
                            <div
                              className="nodeBg-I19043427_16837563_3463404516"
                              id="id-bg-I19043427_16837563_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-3-1888" id="id-19043428">
                <div className="image-1-1118" id="id-I19043428_16837556">
                  <div
                    className="nodeBg-I19043428_16837556"
                    id="id-bg-I19043428_16837556"
                  >
                    {" "}
                  </div>
                  <div className="rate-1-4504" id="id-I19043428_16940059">
                    <div className="text-1-2475" id="id-I19043428_16938781">
                      <div
                        className="this-is-a-text-1-2046"
                        id="id-I19043428_16938781_123344529"
                      >
                        <span className="this-is-a-text-1-2046-0">
                          {"16 reviews"}
                        </span>
                      </div>
                    </div>
                    <div className="frame-5-1-3318" id="id-I19043428_16940129">
                      <div className="text-1-4704" id="id-I19043428_16940065">
                        <div
                          className="this-is-a-text-1-84"
                          id="id-I19043428_16940065_123344529"
                        >
                          <span className="this-is-a-text-1-84-0">{"4.5"}</span>
                        </div>
                      </div>
                      <div
                        className="rate-star-1-3825"
                        id="id-I19043428_16940060"
                      >
                        <div
                          className="icon--starfille-1-40"
                          id="id-I19043428_16940060_39611242"
                        >
                          <div
                            className="vector-1-4050"
                            id="id-I19043428_16940060_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043428_16940060_39611242_35172"
                              id="id-bg-I19043428_16940060_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-2-63"
                        id="id-I19043428_16940061"
                      >
                        <div
                          className="icon--starfille-1-4171"
                          id="id-I19043428_16940061_39611242"
                        >
                          <div
                            className="vector-1-141"
                            id="id-I19043428_16940061_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043428_16940061_39611242_35172"
                              id="id-bg-I19043428_16940061_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-3-3888"
                        id="id-I19043428_16940062"
                      >
                        <div
                          className="icon--starfille-1-2565"
                          id="id-I19043428_16940062_39611242"
                        >
                          <div
                            className="vector-1-2870"
                            id="id-I19043428_16940062_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043428_16940062_39611242_35172"
                              id="id-bg-I19043428_16940062_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-4-3633"
                        id="id-I19043428_16940063"
                      >
                        <div
                          className="icon--starfille-1-396"
                          id="id-I19043428_16940063_39611242"
                        >
                          <div
                            className="vector-1-698"
                            id="id-I19043428_16940063_39611242_35172"
                          >
                            <div
                              className="nodeBg-I19043428_16940063_39611242_35172"
                              id="id-bg-I19043428_16940063_39611242_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="rate-star-5-1247"
                        id="id-I19043428_16940064"
                      >
                        <div
                          className="icon--starfille-1-55"
                          id="id-I19043428_16940064_39611236"
                        >
                          <div
                            className="vector-1-790"
                            id="id-I19043428_16940064_39611236_35172"
                          >
                            <div
                              className="nodeBg-I19043428_16940064_39611236_35172"
                              id="id-bg-I19043428_16940064_39611236_35172"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-1-4074" id="id-I19043428_16837557">
                  <div className="categories-1-575" id="id-I19043428_16837565">
                    <div className="badge-1-2832" id="id-I19043428_16837566">
                      <div
                        className="text-1-2138"
                        id="id-I19043428_16837566_4825411748"
                      >
                        <span className="text-1-2138-0">{"Venue"}</span>
                      </div>
                    </div>
                    <div className="badge-2-432" id="id-I19043428_16837567">
                      <div
                        className="text-1-1539"
                        id="id-I19043428_16837567_4825411748"
                      >
                        <span className="text-1-1539-0">{"Cake"}</span>
                      </div>
                    </div>
                    <div className="badge-3-84" id="id-I19043428_16837568">
                      <div
                        className="text-1-3588"
                        id="id-I19043428_16837568_4825411748"
                      >
                        <span className="text-1-3588-0">{"Photo&Video"}</span>
                      </div>
                    </div>
                    <div className="badge-4-76" id="id-I19043428_16837573">
                      <div
                        className="text-1-1495"
                        id="id-I19043428_16837573_4825411748"
                      >
                        <span className="text-1-1495-0">{"Beauty"}</span>
                      </div>
                    </div>
                    <div className="badge-5-2418" id="id-I19043428_16837672">
                      <div
                        className="text-1-4100"
                        id="id-I19043428_16837672_4825411748"
                      >
                        <span className="text-1-4100-0">{"Catering"}</span>
                      </div>
                    </div>
                    <div className="badge-6-4094" id="id-I19043428_16837694">
                      <div
                        className="text-1-297"
                        id="id-I19043428_16837694_4825411748"
                      >
                        <span className="text-1-297-0">{"DJ"}</span>
                      </div>
                    </div>
                    <div className="badge-7-3190" id="id-I19043428_16837720">
                      <div
                        className="text-1-4947"
                        id="id-I19043428_16837720_4825411748"
                      >
                        <span className="text-1-4947-0">{"Decor"}</span>
                      </div>
                    </div>
                    <div className="badge-8-792" id="id-I19043428_16837750">
                      <div
                        className="text-1-2442"
                        id="id-I19043428_16837750_4825411748"
                      >
                        <span className="text-1-2442-0">{"Jewellery"}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="heading-and-tex-1-1399"
                    id="id-I19043428_16837558"
                  >
                    <div
                      className="olivia-rhye--20-1-396"
                      id="id-I19043428_16837559"
                    >
                      <span className="olivia-rhye--20-1-396-0">
                        {"Sheraton Addis • Addis Ababa"}
                      </span>
                    </div>
                    <div
                      className="supporting-text-1-2640"
                      id="id-I19043428_16837564"
                    >
                      <span className="supporting-text-1-2640-0">
                        {
                          "How do you create compelling presentations that wow your colleagues and impress your managers?"
                        }
                      </span>
                    </div>
                    <div
                      className="heading-and-ico-1-88"
                      id="id-I19043428_16837560"
                    >
                      <div
                        className="heading-1-1743"
                        id="id-I19043428_16837561"
                      >
                        <span className="heading-1-1743-0">
                          {"Yoseph & Mersilas"}
                        </span>
                      </div>
                      <div
                        className="icon-wrap-1-2520"
                        id="id-I19043428_16837562"
                      >
                        <div
                          className="arrowupright-1-104"
                          id="id-I19043428_16837563"
                        >
                          <div
                            className="icon-1-4312"
                            id="id-I19043428_16837563_3463404516"
                          >
                            <div
                              className="nodeBg-I19043428_16837563_3463404516"
                              id="id-bg-I19043428_16837563_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-section-2-738" id="id-19043429">
          <div className="container-1-4125" id="id-19043430">
            <div className="content-1-4958" id="id-19043431">
              <div className="heading-and-sup-1-1274" id="id-19043432">
                <div className="supporting-text-1-3358" id="id-19043436">
                  <span className="supporting-text-1-3358-0">
                    {
                      "Tool and strategies modern teams need to help their companies grow."
                    }
                  </span>
                </div>
                <div className="heading-and-sub-1-4042" id="id-19043433">
                  <div className="subheading-1-276" id="id-19043434">
                    <span className="subheading-1-276-0">{"Our blog"}</span>
                  </div>
                  <div className="heading-1-1254" id="id-19043435">
                    <span className="heading-1-1254-0">
                      {"Vendor Categories"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-2-1360" id="id-19043437">
            <div className="content-1-2890" id="id-19043438">
              <div className="blog-post-card-1-3621" id="id-19043439">
                <div className="image-1-90" id="id-I19043439_1751433">
                  <div
                    className="nodeBg-I19043439_1751433"
                    id="id-bg-I19043439_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-1785" id="id-I19043439_1751443">
                  <div
                    className="heading-and-tex-1-4898"
                    id="id-I19043439_1751444"
                  >
                    <div
                      className="heading-and-ico-1-2632"
                      id="id-I19043439_1751446"
                    >
                      <div className="heading-1-4234" id="id-I19043439_1751447">
                        <span className="heading-1-4234-0">{"Jewelers"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-1260"
                        id="id-I19043439_1751448"
                      >
                        <div
                          className="arrowupright-1-2511"
                          id="id-I19043439_1751449"
                        >
                          <div
                            className="icon-1-4556"
                            id="id-I19043439_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043439_1751449_3463404516"
                              id="id-bg-I19043439_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-2-867" id="id-19043440">
                <div className="image-1-1479" id="id-I19043440_1751433">
                  <div
                    className="nodeBg-I19043440_1751433"
                    id="id-bg-I19043440_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-108" id="id-I19043440_1751443">
                  <div
                    className="heading-and-tex-1-3763"
                    id="id-I19043440_1751444"
                  >
                    <div
                      className="heading-and-ico-1-55"
                      id="id-I19043440_1751446"
                    >
                      <div className="heading-1-4275" id="id-I19043440_1751447">
                        <span className="heading-1-4275-0">{"Car rental"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-2236"
                        id="id-I19043440_1751448"
                      >
                        <div
                          className="arrowupright-1-4506"
                          id="id-I19043440_1751449"
                        >
                          <div
                            className="icon-1-1558"
                            id="id-I19043440_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043440_1751449_3463404516"
                              id="id-bg-I19043440_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-3-9" id="id-19043441">
                <div className="image-1-1927" id="id-I19043441_1751433">
                  <div
                    className="nodeBg-I19043441_1751433"
                    id="id-bg-I19043441_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-4506" id="id-I19043441_1751443">
                  <div
                    className="heading-and-tex-1-3220"
                    id="id-I19043441_1751444"
                  >
                    <div
                      className="heading-and-ico-1-1734"
                      id="id-I19043441_1751446"
                    >
                      <div className="heading-1-90" id="id-I19043441_1751447">
                        <span className="heading-1-90-0">
                          {"Wedding dress"}
                        </span>
                      </div>
                      <div
                        className="icon-wrap-1-2320"
                        id="id-I19043441_1751448"
                      >
                        <div
                          className="arrowupright-1-1534"
                          id="id-I19043441_1751449"
                        >
                          <div
                            className="icon-1-952"
                            id="id-I19043441_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043441_1751449_3463404516"
                              id="id-bg-I19043441_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-4-1190" id="id-19043442">
                <div className="image-1-4399" id="id-I19043442_1751433">
                  <div
                    className="nodeBg-I19043442_1751433"
                    id="id-bg-I19043442_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-1005" id="id-I19043442_1751443">
                  <div
                    className="heading-and-tex-1-208"
                    id="id-I19043442_1751444"
                  >
                    <div
                      className="heading-and-ico-1-966"
                      id="id-I19043442_1751446"
                    >
                      <div className="heading-1-1680" id="id-I19043442_1751447">
                        <span className="heading-1-1680-0">{"Makeup"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-2552"
                        id="id-I19043442_1751448"
                      >
                        <div
                          className="arrowupright-1-4160"
                          id="id-I19043442_1751449"
                        >
                          <div
                            className="icon-1-126"
                            id="id-I19043442_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043442_1751449_3463404516"
                              id="id-bg-I19043442_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-5-1380" id="id-19043443">
                <div className="image-1-3731" id="id-I19043443_1751433">
                  <div
                    className="nodeBg-I19043443_1751433"
                    id="id-bg-I19043443_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-114" id="id-I19043443_1751443">
                  <div
                    className="heading-and-tex-1-3192"
                    id="id-I19043443_1751444"
                  >
                    <div
                      className="heading-and-ico-1-1497"
                      id="id-I19043443_1751446"
                    >
                      <div className="heading-1-2304" id="id-I19043443_1751447">
                        <span className="heading-1-2304-0">
                          {"Photography"}
                        </span>
                      </div>
                      <div
                        className="icon-wrap-1-243"
                        id="id-I19043443_1751448"
                      >
                        <div
                          className="arrowupright-1-1922"
                          id="id-I19043443_1751449"
                        >
                          <div
                            className="icon-1-1125"
                            id="id-I19043443_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043443_1751449_3463404516"
                              id="id-bg-I19043443_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-6-4818" id="id-19043444">
                <div className="image-1-346" id="id-I19043444_1751433">
                  <div
                    className="nodeBg-I19043444_1751433"
                    id="id-bg-I19043444_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-3294" id="id-I19043444_1751443">
                  <div
                    className="heading-and-tex-1-1178"
                    id="id-I19043444_1751444"
                  >
                    <div
                      className="heading-and-ico-1-504"
                      id="id-I19043444_1751446"
                    >
                      <div className="heading-1-595" id="id-I19043444_1751447">
                        <span className="heading-1-595-0">{"Videography"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-1085"
                        id="id-I19043444_1751448"
                      >
                        <div
                          className="arrowupright-1-473"
                          id="id-I19043444_1751449"
                        >
                          <div
                            className="icon-1-1656"
                            id="id-I19043444_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043444_1751449_3463404516"
                              id="id-bg-I19043444_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-7-1480" id="id-19043445">
                <div className="image-1-364" id="id-I19043445_1751433">
                  <div
                    className="nodeBg-I19043445_1751433"
                    id="id-bg-I19043445_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-632" id="id-I19043445_1751443">
                  <div
                    className="heading-and-tex-1-4416"
                    id="id-I19043445_1751444"
                  >
                    <div
                      className="heading-and-ico-1-195"
                      id="id-I19043445_1751446"
                    >
                      <div className="heading-1-2552" id="id-I19043445_1751447">
                        <span className="heading-1-2552-0">{"Cake"}</span>
                      </div>
                      <div className="icon-wrap-1-44" id="id-I19043445_1751448">
                        <div
                          className="arrowupright-1-1912"
                          id="id-I19043445_1751449"
                        >
                          <div
                            className="icon-1-4620"
                            id="id-I19043445_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043445_1751449_3463404516"
                              id="id-bg-I19043445_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-8-2952" id="id-19043446">
                <div className="image-1-50" id="id-I19043446_1751433">
                  <div
                    className="nodeBg-I19043446_1751433"
                    id="id-bg-I19043446_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-3672" id="id-I19043446_1751443">
                  <div
                    className="heading-and-tex-1-243"
                    id="id-I19043446_1751444"
                  >
                    <div
                      className="heading-and-ico-1-3186"
                      id="id-I19043446_1751446"
                    >
                      <div className="heading-1-930" id="id-I19043446_1751447">
                        <span className="heading-1-930-0">{"Dj"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-1831"
                        id="id-I19043446_1751448"
                      >
                        <div
                          className="arrowupright-1-4788"
                          id="id-I19043446_1751449"
                        >
                          <div
                            className="icon-1-1240"
                            id="id-I19043446_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043446_1751449_3463404516"
                              id="id-bg-I19043446_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-9-752" id="id-19043447">
                <div className="image-1-900" id="id-I19043447_1751433">
                  <div
                    className="nodeBg-I19043447_1751433"
                    id="id-bg-I19043447_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-1242" id="id-I19043447_1751443">
                  <div
                    className="heading-and-tex-1-1794"
                    id="id-I19043447_1751444"
                  >
                    <div
                      className="heading-and-ico-1-1426"
                      id="id-I19043447_1751446"
                    >
                      <div className="heading-1-2592" id="id-I19043447_1751447">
                        <span className="heading-1-2592-0">{"Catering"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-1166"
                        id="id-I19043447_1751448"
                      >
                        <div
                          className="arrowupright-1-2442"
                          id="id-I19043447_1751449"
                        >
                          <div
                            className="icon-1-4992"
                            id="id-I19043447_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043447_1751449_3463404516"
                              id="id-bg-I19043447_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-card-10-273" id="id-19043448">
                <div className="image-1-3363" id="id-I19043448_1751433">
                  <div
                    className="nodeBg-I19043448_1751433"
                    id="id-bg-I19043448_1751433"
                  >
                    {" "}
                  </div>
                </div>
                <div className="content-1-4095" id="id-I19043448_1751443">
                  <div
                    className="heading-and-tex-1-484"
                    id="id-I19043448_1751444"
                  >
                    <div
                      className="heading-and-ico-1-798"
                      id="id-I19043448_1751446"
                    >
                      <div className="heading-1-572" id="id-I19043448_1751447">
                        <span className="heading-1-572-0">{"Venues"}</span>
                      </div>
                      <div
                        className="icon-wrap-1-3905"
                        id="id-I19043448_1751448"
                      >
                        <div
                          className="arrowupright-1-4266"
                          id="id-I19043448_1751449"
                        >
                          <div
                            className="icon-1-4794"
                            id="id-I19043448_1751449_3463404516"
                          >
                            <div
                              className="nodeBg-I19043448_1751449_3463404516"
                              id="id-bg-I19043448_1751449_3463404516"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-91-0-3019" id="id-1765711">
          <div className="image-1-2128" id="id-1765722">
            <div className="rectangle-1-1-2760" id="id-1765723">
              <div className="nodeBg-1765723" id="id-bg-1765723">
                {" "}
              </div>
            </div>
            <div className="rectangle-2-1-780" id="id-1765724">
              <div className="nodeBg-1765724" id="id-bg-1765724">
                {" "}
              </div>
            </div>
          </div>
          <div className="content-1-3266" id="id-1765712">
            <div className="supporting-text-1-4653" id="id-1765721">
              <span className="supporting-text-1-4653-0">
                {
                  "Powerful, self-serve product and growth analytics to help you convert, engage, and retain more."
                }
              </span>
            </div>
            <div className="frame-10-1-1416" id="id-1765714">
              <div className="heading-1-620" id="id-1765715">
                <span className="heading-1-620-0">
                  {"Finding the best vendors in sheger"}
                </span>
              </div>
              <div className="supporting-text-1-3520" id="id-1765716">
                <span className="supporting-text-1-3520-0">
                  {
                    "Discover the perfect wedding and event vendor for your special day!"
                  }
                </span>
              </div>
            </div>
            <div className="badge-group-1-3528" id="id-1765713">
              <div className="badge-1-640" id="id-I1765713_4826405942">
                <div
                  className="text-1-4876"
                  id="id-I1765713_4826405942_4825411751"
                >
                  <span className="text-1-4876-0">{"We’re hiring!"}</span>
                </div>
                <div
                  className="dot-1-12"
                  id="id-I1765713_4826405942_4825411750"
                >
                  <div
                    className="dot-1-594"
                    id="id-I1765713_4826405942_4825411750_4840410124"
                  ></div>
                </div>
              </div>
              <div className="content-1-702" id="id-I1765713_4825413042">
                <div className="message-1-640" id="id-I1765713_4825413043">
                  <span className="message-1-640-0">
                    {"Join our remote team"}
                  </span>
                </div>
                <div className="arrowright-1-1232" id="id-I1765713_4825413044">
                  <div
                    className="icon-1-552"
                    id="id-I1765713_4825413044_3463404483"
                  >
                    <div
                      className="nodeBg-I1765713_4825413044_3463404483"
                      id="id-bg-I1765713_4825413044_3463404483"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="email-capture-1-2880" id="id-1765717">
              <div className="buttonsbutton-1-3403" id="id-1765720">
                <div className="text-padding-1-300" id="id-I1765720_6421283565">
                  <div className="text-1-1800" id="id-I1765720_3287432937">
                    <span className="text-1-1800-0">{"Find Vendors"}</span>
                  </div>
                </div>
              </div>
              <div className="input-field-1-1316" id="id-1765718">
                <div
                  className="input-with-labe-1-150"
                  id="id-I1765718_3285380141"
                >
                  <div className="input-1-1378" id="id-I1765718_3285380143">
                    <div
                      className="dropdown-1-4582"
                      id="id-I1765718_3285380149"
                    >
                      <div
                        className="currencydollar-1-2432"
                        id="id-I1765718_3285380151"
                      ></div>
                    </div>
                    <div className="content-1-1170" id="id-I1765718_3285380144">
                      <div
                        className="text-input-1-1380"
                        id="id-I1765718_3285380145"
                      >
                        <div
                          className="leading-text-1-1825"
                          id="id-I1765718_3285380146"
                        >
                          <span className="leading-text-1-1825-0">{"Br"}</span>
                        </div>
                        <div
                          className="text-1-3696"
                          id="id-I1765718_3285380147"
                        >
                          <span className="text-1-3696-0">
                            {"Set a budget range"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-field-2-4015" id="id-1765719">
                <div
                  className="input-with-labe-1-96"
                  id="id-I1765719_3285380141"
                >
                  <div className="input-1-2368" id="id-I1765719_3285380143">
                    <div className="content-1-528" id="id-I1765719_3285380144">
                      <div
                        className="text-input-1-1425"
                        id="id-I1765719_3285380145"
                      >
                        <div className="text-1-715" id="id-I1765719_3285380147">
                          <span className="text-1-715-0">{"Vendors"}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="dropdown-1-3239"
                      id="id-I1765719_3285380149"
                    >
                      <div
                        className="chevrondown-1-336"
                        id="id-I1765719_3285380151"
                      >
                        <div
                          className="icon-1-34"
                          id="id-I1765719_3285380151_3463404534"
                        >
                          <div
                            className="nodeBg-I1765719_3285380151_3463404534"
                            id="id-bg-I1765719_3285380151_3463404534"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="image1-0-4114" id="id-18843360">
          <div className="nodeBg-18843360" id="id-bg-18843360">
            {" "}
          </div>
        </div>
        <div className="content1-0-380" id="id-18843361">
          <div className="heading-and-tex-1-585" id="id-18843362">
            <div className="heading-and-ico-1-4161" id="id-18843363">
              <div className="heading-1-288" id="id-18843364">
                <span className="heading-1-288-0">{"Wedding dress"}</span>
              </div>
              <div className="icon-wrap-1-340" id="id-18843365">
                <div className="arrowupright-1-1664" id="id-18843366">
                  <div className="icon-1-801" id="id-I18843366_3463404516">
                    <div
                      className="nodeBg-I18843366_3463404516"
                      id="id-bg-I18843366_3463404516"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-padding1-0-144" id="id-14284">
          <div className="text-1-1440" id="id-14285">
            <span className="text-1-1440-0">{"Button CTA"}</span>
          </div>
        </div>
        <div className="input-with-labe-0-3276" id="id-112871">
          <div className="label-1-1040" id="id-112872">
            <span className="label-1-1040-0">{"Sale amount"}</span>
          </div>
          <div className="input-1-1864" id="id-112873">
            <div className="dropdown-1-2499" id="id-112879">
              <div className="dropdown-text-1-1376" id="id-112880">
                <span className="dropdown-text-1-1376-0">{"USD"}</span>
              </div>
              <div className="chevrondown-1-632" id="id-112881">
                <div className="icon-1-1762" id="id-I112881_3463404534">
                  <div
                    className="nodeBg-I112881_3463404534"
                    id="id-bg-I112881_3463404534"
                  ></div>
                </div>
              </div>
            </div>
            <div className="content-1-2666" id="id-112874">
              <div className="text-input-1-3145" id="id-112875">
                <div className="leading-text-1-45" id="id-112876">
                  <span className="leading-text-1-45-0">{"$"}</span>
                </div>
                <div className="text-1-252" id="id-112877">
                  <span className="text-1-252-0">{"1,000.00"}</span>
                </div>
              </div>
              <div className="help-icon-1-3224" id="id-112878">
                <div className="helpcircle-1-657" id="id-I112878_105467">
                  <div className="icon-1-0" id="id-I112878_105467_3463405412">
                    <div
                      className="nodeBg-I112878_105467_3463405412"
                      id="id-bg-I112878_105467_3463405412"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu1-0-2597" id="id-16785">
          <div className="content-1-3042" id="id-16786">
            <div className="nav-menu-item-1-297" id="id-16787">
              <div className="content-1-98" id="id-I16787_128214433">
                <div className="text-and-suppor-1-412" id="id-I16787_128214434">
                  <div
                    className="the-latest-indu-1-2850"
                    id="id-I16787_128214438"
                  >
                    <span className="the-latest-indu-1-2850-0">
                      {"The latest industry news, updates and info."}
                    </span>
                  </div>
                  <div
                    className="text-and-badge-1-1224"
                    id="id-I16787_128214435"
                  >
                    <div className="text-1-2227" id="id-I16787_128214436">
                      <span className="text-1-2227-0">{"Blog"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookclosed-1-129" id="id-I16787_3474462889">
                <div
                  className="icon-1-1180"
                  id="id-I16787_3474462889_3463404148"
                >
                  <div
                    className="nodeBg-I16787_3474462889_3463404148"
                    id="id-bg-I16787_3474462889_3463404148"
                  ></div>
                </div>
              </div>
            </div>
            <div className="nav-menu-item-2-1920" id="id-16788">
              <div className="content-1-4108" id="id-I16788_128214433">
                <div className="text-and-suppor-1-168" id="id-I16788_128214434">
                  <div
                    className="supporting-text-1-1885"
                    id="id-I16788_128214438"
                  >
                    <span className="supporting-text-1-1885-0">
                      {"Learn how our customers are making big changes."}
                    </span>
                  </div>
                  <div
                    className="text-and-badge-1-1541"
                    id="id-I16788_128214435"
                  >
                    <div className="text-1-2154" id="id-I16788_128214436">
                      <span className="text-1-2154-0">
                        {"Customer stories"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stars02-1-1275" id="id-I16788_3474462889">
                <div
                  className="icon-1-4602"
                  id="id-I16788_3474462889_3463407150"
                >
                  <div
                    className="nodeBg-I16788_3474462889_3463407150"
                    id="id-bg-I16788_3474462889_3463407150"
                  ></div>
                </div>
              </div>
            </div>
            <div className="nav-menu-item-3-243" id="id-16789">
              <div className="content-1-3148" id="id-I16789_128214433">
                <div className="text-and-suppor-1-0" id="id-I16789_128214434">
                  <div
                    className="get-up-and-runn-1-187"
                    id="id-I16789_128214438"
                  >
                    <span className="get-up-and-runn-1-187-0">
                      {"Get up and running on new features and techniques."}
                    </span>
                  </div>
                  <div
                    className="text-and-badge-1-3344"
                    id="id-I16789_128214435"
                  >
                    <div className="text-1-1248" id="id-I16789_128214436">
                      <span className="text-1-1248-0">{"Video tutorials"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playcircle-1-275" id="id-I16789_3474462889">
                <div className="icon-1-0" id="id-I16789_3474462889_3463405927">
                  <div
                    className="nodeBg-I16789_3474462889_3463405927"
                    id="id-bg-I16789_3474462889_3463405927"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-2-3551" id="id-16790">
            <div className="nav-menu-item-c-1-450" id="id-16791">
              <div className="content-1-4165" id="id-I16791_128219897">
                <div
                  className="text-and-suppor-1-3132"
                  id="id-I16791_128219898"
                >
                  <div className="text-1-432" id="id-I16791_128219899">
                    <span className="text-1-432-0">
                      {"We've just released an update!"}
                    </span>
                  </div>
                  <div
                    className="supporting-text-1-742"
                    id="id-I16791_128219900"
                  >
                    <span className="supporting-text-1-742-0">
                      {
                        "Check out the all new dashboard view. Pages now load faster. "
                      }
                    </span>
                  </div>
                </div>
                <div className="actions-1-18" id="id-I16791_128219901">
                  <div
                    className="buttonsbutton-1-2024"
                    id="id-I16791_128219902"
                  >
                    <div
                      className="text-1-210"
                      id="id-I16791_128219902_3287433377"
                    >
                      <span className="text-1-210-0">{"Dismiss"}</span>
                    </div>
                  </div>
                  <div
                    className="buttonsbutton-2-2142"
                    id="id-I16791_128219903"
                  >
                    <div
                      className="text-1-1183"
                      id="id-I16791_128219903_3287433289"
                    >
                      <span className="text-1-1183-0">{"Changelog"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="image-wrap-1-4420" id="id-I16791_128219895">
                <div className="image-1-462" id="id-I16791_128219896">
                  <div
                    className="nodeBg-I16791_128219896"
                    id="id-bg-I16791_128219896"
                  >
                    {" "}
                  </div>
                </div>
                <div className="play-button-1-1824" id="id-I16791_2647402504">
                  <div
                    className="button-1-780"
                    id="id-I16791_2647402504_137712743"
                  >
                    <div
                      className="nodeBg-I16791_2647402504_137712743"
                      id="id-bg-I16791_2647402504_137712743"
                    >
                      {" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group2-0-3344" id="id-1765728">
          <div className="vector-1-1748" id="id-1765729">
            <div className="nodeBg-1765729" id="id-bg-1765729"></div>
          </div>
          <div className="vector-2-600" id="id-1765730">
            <div className="nodeBg-1765730" id="id-bg-1765730"></div>
          </div>
        </div>
        <div className="group3-0-87" id="id-1765731">
          <div className="vector-1-1340" id="id-1765732">
            <div className="nodeBg-1765732" id="id-bg-1765732"></div>
          </div>
          <div className="vector-2-245" id="id-1765733">
            <div className="nodeBg-1765733" id="id-bg-1765733"></div>
          </div>
        </div>
        <div className="group4-0-252" id="id-1765734">
          <div className="vector-1-3255" id="id-1765735">
            <div className="nodeBg-1765735" id="id-bg-1765735"></div>
          </div>
          <div className="vector-2-3108" id="id-1765736">
            <div className="nodeBg-1765736" id="id-bg-1765736"></div>
          </div>
        </div>
        <div className="placeholder2-0-2088" id="id-14286">
          <div className="icon-1-1204" id="id-I14286_3465410041">
            <div
              className="nodeBg-I14286_3465410041"
              id="id-bg-I14286_3465410041"
            ></div>
          </div>
        </div>
        <div className="buttonsbutton1-0-1102" id="id-16784">
          <div className="text-1-415" id="id-I16784_3287433397">
            <span className="text-1-415-0">{"Resources"}</span>
          </div>
          <div className="chevronup-1-3004" id="id-I16784_3468468154">
            <div className="icon-1-3854" id="id-I16784_3468468154_3463404558">
              <div
                className="nodeBg-I16784_3468468154_3463404558"
                id="id-bg-I16784_3468468154_3463404558"
              ></div>
            </div>
          </div>
        </div>
        <div className="text1-0-4278" id="id-14275">
          <span className="text1-0-4278-0">{"Button CTA"}</span>
        </div>
        <div className="hero-header-sec-1-3478" id="id-19043400">
          <div className="dropdown-header-1-1275" id="id-19043401">
            <div className="header-1-182" id="id-19043402">
              <div className="container-1-250" id="id-19043403">
                <div className="navigation-acti-1-759" id="id-19043410">
                  <div className="buttonsbutton-1-500" id="id-19043411">
                    <div
                      className="text-padding-1-4183"
                      id="id-I19043411_6421275185"
                    >
                      <div className="text-1-297" id="id-I19043411_3287433257">
                        <span className="text-1-297-0">
                          {"Are you a Vendor?"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="buttonsbutton-2-3713" id="id-19043412">
                    <div
                      className="text-padding-1-312"
                      id="id-I19043412_6421275185"
                    >
                      <div className="text-1-2275" id="id-I19043412_3287433257">
                        <span className="text-1-2275-0">{"Log in"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="buttonsbutton-3-714" id="id-19043413">
                    <div
                      className="text-padding-1-2744"
                      id="id-I19043413_6421273911"
                    >
                      <div className="text-1-16" id="id-I19043413_3287432949">
                        <span className="text-1-16-0">
                          {"Get Started Now!"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="buttonsbutton-4-2419" id="id-19043414">
                  <Link to="/am">
                    <div
                      className="text-padding-1-1370"
                      id="id-I19043414_6421275185"
                    >
                      <div className="text-1-493" id="id-I19043414_3287433257">
                        <span className="text-1-493-0">{"EN"}</span>
                      </div>
                    </div>
                    <div
                      className="translate02-1-533"
                      id="id-I19043414_3468417601"
                    >
                      <div
                        className="icon-1-4575"
                        id="id-I19043414_3468417601_3463405127"
                      >
                        <div
                          className="nodeBg-I19043414_3468417601_3463405127"
                          id="id-bg-I19043414_3468417601_3463405127"
                        ></div>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
                <div className="content-1-320" id="id-19043404">
                  <div className="navigation-1-1632" id="id-19043406">
                    <div
                      className="dropdown-header-2-3099"
                      id="id-19043408"
                    ></div>
                    <div className="buttonsbutton-1-329" id="id-19043409">
                      <div className="text-1-1620" id="id-I19043409_3287433397">
                        <span className="text-1-1620-0">{"About"}</span>
                      </div>
                    </div>
                    <div className="dropdown-header-1-4623" id="id-19043407">
                     <Link to="/venue">
                      <div
                        className="buttonsbutton-1-198"
                        id="id-I19043407_1288478"
                      >
                        <div
                          className="text-1-3822"
                          id="id-I19043407_1288478_3287433397"
                        >
                          <span className="text-1-3822-0">{"Vendors"}</span>
                        </div>
                        <div
                          className="chevrondown-1-1696"
                          id="id-I19043407_1288478_3468468154"
                        >
                          <div
                            className="icon-1-870"
                            id="id-I19043407_1288478_3468468154_3463404534"
                          >
                            <div
                              className="nodeBg-I19043407_1288478_3468468154_3463404534"
                              id="id-bg-I19043407_1288478_3468468154_3463404534"
                            ></div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="tilosh-1-2592" id="id-19043405">
                    <div className="tilosh-1-744" id="id-I19043405_1612120">
                      <div
                        className="layer-1-1-4690"
                        id="id-I19043405_1612120_33235"
                      >
                        <div
                          className="group-1-88"
                          id="id-I19043405_1612120_33236"
                        >
                          <div
                            className="group-1-3822"
                            id="id-I19043405_1612120_33237"
                          >
                            <div
                              className="vector-1-2976"
                              id="id-I19043405_1612120_33238"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33238"
                                id="id-bg-I19043405_1612120_33238"
                              ></div>
                            </div>
                            <div
                              className="vector-2-1408"
                              id="id-I19043405_1612120_33239"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33239"
                                id="id-bg-I19043405_1612120_33239"
                              ></div>
                            </div>
                            <div
                              className="vector-3-1020"
                              id="id-I19043405_1612120_33240"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33240"
                                id="id-bg-I19043405_1612120_33240"
                              ></div>
                            </div>
                            <div
                              className="vector-4-1395"
                              id="id-I19043405_1612120_33241"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33241"
                                id="id-bg-I19043405_1612120_33241"
                              ></div>
                            </div>
                            <div
                              className="vector-5-700"
                              id="id-I19043405_1612120_33242"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33242"
                                id="id-bg-I19043405_1612120_33242"
                              ></div>
                            </div>
                            <div
                              className="vector-6-320"
                              id="id-I19043405_1612120_33243"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33243"
                                id="id-bg-I19043405_1612120_33243"
                              ></div>
                            </div>
                          </div>
                          <div
                            className="group-2-2250"
                            id="id-I19043405_1612120_33244"
                          >
                            <div
                              className="vector-1-105"
                              id="id-I19043405_1612120_33245"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33245"
                                id="id-bg-I19043405_1612120_33245"
                              ></div>
                            </div>
                            <div
                              className="vector-2-2231"
                              id="id-I19043405_1612120_33246"
                            >
                              <div
                                className="nodeBg-I19043405_1612120_33246"
                                id="id-bg-I19043405_1612120_33246"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-1-135" id="id-19043415">
            <div className="group-1-5" id="id-I19043415_1762591">
              <div className="vector-1-1584" id="id-I19043415_1762592">
                <div
                  className="nodeBg-I19043415_1762592"
                  id="id-bg-I19043415_1762592"
                ></div>
              </div>
              <div className="vector-2-530" id="id-I19043415_1762593">
                <div
                  className="nodeBg-I19043415_1762593"
                  id="id-bg-I19043415_1762593"
                ></div>
              </div>
            </div>
            <div className="group-2-3416" id="id-I19043415_1765439">
              <div className="vector-1-3234" id="id-I19043415_1765440">
                <div
                  className="nodeBg-I19043415_1765440"
                  id="id-bg-I19043415_1765440"
                ></div>
              </div>
              <div className="vector-2-3783" id="id-I19043415_1765441">
                <div
                  className="nodeBg-I19043415_1765441"
                  id="id-bg-I19043415_1765441"
                ></div>
              </div>
            </div>
            <div className="group-3-2303" id="id-I19043415_1765448">
              <div className="vector-1-540" id="id-I19043415_1765449">
                <div
                  className="nodeBg-I19043415_1765449"
                  id="id-bg-I19043415_1765449"
                ></div>
              </div>
              <div className="vector-2-3612" id="id-I19043415_1765450">
                <div
                  className="nodeBg-I19043415_1765450"
                  id="id-bg-I19043415_1765450"
                ></div>
              </div>
            </div>
            <div className="group-4-1638" id="id-I19043415_1765442">
              <div className="vector-1-851" id="id-I19043415_1765443">
                <div
                  className="nodeBg-I19043415_1765443"
                  id="id-bg-I19043415_1765443"
                ></div>
              </div>
              <div className="vector-2-216" id="id-I19043415_1765444">
                <div
                  className="nodeBg-I19043415_1765444"
                  id="id-bg-I19043415_1765444"
                ></div>
              </div>
            </div>
            <div className="frame-9-1-806" id="id-I19043415_1765513">
              <div className="image-1-1521" id="id-I19043415_1751664">
                <div className="rectangle-1-1-1875" id="id-I19043415_1751668">
                  <div
                    className="nodeBg-I19043415_1751668"
                    id="id-bg-I19043415_1751668"
                  >
                    {" "}
                  </div>
                </div>
                <div className="rectangle-2-1-148" id="id-I19043415_1751669">
                  <div
                    className="nodeBg-I19043415_1751669"
                    id="id-bg-I19043415_1751669"
                  >
                    {" "}
                  </div>
                </div>
              </div>
              <div className="content-1-178" id="id-I19043415_1751633">
                <div className="frame-10-1-3880" id="id-I19043415_1765586">
                  <div className="heading-1-2856" id="id-I19043415_1751637">
                    <span className="heading-1-2856-0">
                      {"Finding the best event vendors "}
                    </span>
                  </div>
                  <div
                    className="supporting-text-1-656"
                    id="id-I19043415_1751638"
                  >
                    <span className="supporting-text-1-656-0">
                      {
                        "Discover the perfect wedding and event vendors for your special day!"
                      }
                    </span>
                  </div>
                </div>
                <div className="email-capture-1-3696" id="id-I19043415_1751639">
                  <div
                    className="buttonsbutton-1-3526"
                    id="id-I19043415_1751642"
                  >
                    <div
                      className="text-padding-1-800"
                      id="id-I19043415_1751642_6421283565"
                    >
                      <div
                        className="text-1-4620"
                        id="id-I19043415_1751642_3287432937"
                      >
                        <span className="text-1-4620-0">{"Find Vendors"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="input-field-1-2254" id="id-I19043415_1751640">
                    <div
                      className="input-with-labe-1-3645"
                      id="id-I19043415_1751640_3285380141"
                    >
                      <div
                        className="input-1-2695"
                        id="id-I19043415_1751640_3285380143"
                      >
                        <div
                          className="dropdown-1-472"
                          id="id-I19043415_1751640_3285380149"
                        >
                          <div
                            className="currencydollar-1-2436"
                            id="id-I19043415_1751640_3285380151"
                          ></div>
                        </div>
                        <div
                          className="content-1-1881"
                          id="id-I19043415_1751640_3285380144"
                        >
                          <div
                            className="text-input-1-923"
                            id="id-I19043415_1751640_3285380145"
                          >
                            <div
                              className="leading-text-1-312"
                              id="id-I19043415_1751640_3285380146"
                            >
                              <span className="leading-text-1-312-0">
                                {"Br"}
                              </span>
                            </div>
                            <div
                              className="text-1-2952"
                              id="id-I19043415_1751640_3285380147"
                            >
                              <span className="text-1-2952-0">
                                {"Set a budget range"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field-2-476" id="id-I19043415_1751641">
                    <div
                      className="input-with-labe-1-893"
                      id="id-I19043415_1751641_3285380141"
                    >
                      <div
                        className="input-1-610"
                        id="id-I19043415_1751641_3285380143"
                      >
                        <div
                          className="content-1-903"
                          id="id-I19043415_1751641_3285380144"
                        >
                          <div
                            className="text-input-1-595"
                            id="id-I19043415_1751641_3285380145"
                          >
                            <div
                              className="text-1-957"
                              id="id-I19043415_1751641_3285380147"
                            >
                              <span className="text-1-957-0">{"Vendors"}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className="dropdown-1-1353"
                          id="id-I19043415_1751641_3285380149"
                        >
                          <div
                            className="chevrondown-1-295"
                            id="id-I19043415_1751641_3285380151"
                          >
                            <div
                              className="icon-1-3834"
                              id="id-I19043415_1751641_3285380151_3463404534"
                            >
                              <div
                                className="nodeBg-I19043415_1751641_3285380151_3463404534"
                                id="id-bg-I19043415_1751641_3285380151_3463404534"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home
