const BlogCard=({data})=>{
  console.log(data)
	return(
		<>
		 <div className="blog-post-card-1-28-vd">
                    <div className="image-1-19-vd">
                      <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${data.medias?.split(",")[0]})` }}>
                        {" "}
                      </div>
                    </div>
                    <div className="content-1-322-vd">
                        <div className="heading-1-160">
                            <span className="heading-1-160-0-vd">
                              {data.productName}
                            </span>
                        </div>
                        <div className="buttonsbutton-1-vd">
                          <span className="text-1-1-0-vd">{"Add to Cart"}</span>
                  		</div>
                      </div>
                  </div>
		</>
		)
}
export default BlogCard