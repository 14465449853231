import BlogCard from './BlogCard'
import sheraton from "../../../Assets/images/Image42.png"
import hilton from "../../../Assets/images/Image43.png"
import haile from "../../../Assets/images/Image44.png"
import ghion from "../../../Assets/images/Image45.png"
import image46 from "../../../Assets/images/Image46.png"
import image47 from "../../../Assets/images/Image47.png"
import image48 from "../../../Assets/images/Image48.png"
import image49 from "../../../Assets/images/Image49.png"
const Blog=()=>{
  const eventList=[
  {
    img:sheraton
  },
  {
    img:hilton
  },
  {
    img:haile
  },
  {
    img:ghion
  }
  ]
  const aboutList=[
  {
    img:image46
  },
  {
    img:image47
  },
  {
    img:image48
  },
  {
    img:image49
  }
  ]
	return(
		<>
		<div className="blog__container">
	    <div className="blog__content">
	    <div className="blog__title">About Tilosh: Crafting Your Perfect Wedding</div>
	    <div className="blog_description">Welcome to Tilosh, your premier destination for seamless wedding planning
	     and communication. At Tilosh, we understand that your wedding day is one of the most significant moments of 
	     your life. It’s not just an event; it’s a celebration of love, commitment, and dreams coming true. With this 
	     understanding at our core, we strive to make the journey to your special day as smooth, stress-free, and 
	     magical as possible</div>
	    <div className="blog__title">Our Mission</div>
	    <div className="blog_description">At Tilosh, our mission is simple yet profound: to transform your wedding 
	    dreams into a breathtaking reality. We believe that every couple deserves a wedding that reflects their unique 
	    love story and individual style. Whether you envision an intimate garden ceremony, a lavish ballroom affair, or 
	    a destination wedding by the sea, we are here to bring your vision to life. Our dedicated team is committed to 
	    providing unparalleled support, creativity, and attention to detail every step of the way</div>
	    <div className="blog__title">What Sets Us Apart</div>
	    <div className="blog_description">What sets Tilosh apart is our unwavering dedication to excellence and innovation 
	    in the wedding industry. We understand that planning a wedding can be overwhelming, which is why we’ve created a 
	    platform that streamlines the process and connects you with top-tier vendors who share our commitment to quality 
	    and service. With Tilosh, you can say goodbye to endless spreadsheets, missed emails, and last-minute panics. 
	    Our user-friendly interface and comprehensive tools empower you to plan, communicate, and collaborate with ease, 
	    ensuring that every aspect of your wedding is meticulously coordinated and flawlessly executed.</div>
	    <div className="blog__title">Some of Our Succesful events</div>
	    <div className="blog__cards">
	    {eventList.map(x => ( 
	                  <BlogCard
	                  data={x} />
	                  ))}
	    </div>
	    <div className="blog__cards">
	    {aboutList.map(x => ( 
	                  <BlogCard
	                  data={x} />
	                  ))}
	    </div>
	    </div>
	    </div>
		</>
		)
}
export default Blog