import { axios } from '../../../lib/axios';

export const getUserProfile = (id) => { 
  return axios.get('/users/'+id);
};

export const getProducts = (id) => { 
  return axios.get('/products');
};


export const createProduct = (data) => {
  return axios.post('/products', data,{
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      });
};


export const createPackage = (data) => {
  return axios.post('/packages', data,{
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      });
};


export const product = (data) => {
  return axios.post('/products/user', {
      ...data
  });
};


export const productList = (data) => {
  return axios.post('/products/category', {
      ...data
  });
};

export const packages = (data) => {
  return axios.post('/packages/user', {
      ...data
  });
};

