import { axios } from '../../../lib/axios';



export const getProducts = (id) => { 
  return axios.get('/budgets');
};


export const createProduct = (data) => {
  return axios.post('/budgets', data,{
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      });
};


export const getCarts = (data) => {
  return axios.post('/carts/user', {
      ...data
  });
};

