import BlogCard from './BlogCard'
import sheraton from "../../../Assets/service-images/image3.png"
import hilton from "../../../Assets/service-images/image4.png"
import haile from "../../../Assets/service-images/image5.png"
import ghion from "../../../Assets/service-images/image6.png"
import sheraton1 from "../../../Assets/service-images/image7.png"
import haile1 from "../../../Assets/service-images/image8.png"
const Blog=()=>{
  const venueList=[
  {
    name:'Sheraton Addis hotel',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 100k',
    guest:'300 Guests',
    img:sheraton
  },
  {
    name:'Hilton hotel',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 200k',
    guest:'400 Guests',
    img:hilton
  },
  {
    name:'Haile Resort',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 300k',
    guest:'500 Guests',
    img:haile
  },
  {
    name:'Ghion hotel',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 100k',
    guest:'300 Guests',
    img:ghion
  },
  {
    name:'Sheraton Addis hotel',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 200k',
    guest:'400 Guests',
    img:sheraton1
  },
  {
    name:'Haile Resort',
    description:'How do you create compelling presentations that wow your colleagues and impress your managers?',
    price:'Br. 300k',
    guest:'500 Guests',
    img:haile1
  },
  ]
	return(
		<>
        <div className="blog-section">
            <div className="rectangle-3">
              <div className="nodeBg-19043586">
                {" "}
              </div>
            </div>
            <div className="frame-13">
                      <div className="frame-14">
                        <div className="heading-1-1617">
                          <span className="heading-1-1617-0">
                            {`${venueList.length} Venues around your budget`}
                          </span>
                        </div>
                        <div className="icon-wrap-1-828">
                            <div className="icon-1-684">
                              <div className="nodeBg-I19043595_3463404543"></div>
                            </div>
                        </div>
                      </div>
                      <div className="email-capture">
                        <div className="input-field-1">
                            <div className="input-1-14">
                                <div className="text-input-1-8">
                                    <span className="leading-text-1-2808-0">
                                      {"$"}
                                    </span>
                                    <span className="text-1-792-0">
                                      {"200k - 300k"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="input-field-2">
                            <div className="input-1-85">
                                    <span className="text-1-20">
                                      {"Venues"}
                                    </span>
                          </div>
                        </div>
                        <div className="buttonsbutton-1">
                          <div className="icon--searchout-1-2890">
                              <div className="nodeBg-I19043600_3471597214_3231752"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
               <div className="container-1-988">
                <div className="content-1-1">
                 {venueList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
                  </div>
                  </div>
                  </>
            )
}
export default Blog