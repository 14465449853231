import React  from "react";
import "../Venue/Venue.css";
import "./Services.css";
import Header from "../Venue/components/Header";
import Blog from "./components/Blog"
const Services=()=>{
	return(
    <>
    <Header />
    <Blog />
    </>
		)
}
export default Services