import user from '../../../Assets/images/Content.png';
const MessageList=({data})=>{
	return(
		<div className="message_container">
		<div className="sender_profile">
		<img src={user} className="sender_img" alt="logo" />
		<div className="message_sender">
		<span className="sender_name">{data.name}</span>
		<span className="sender_profession">{data.profession}</span>
		</div>
		</div>
		<div className="last_message">
		{data.message}
		</div>
		</div>
		)
}
export default MessageList