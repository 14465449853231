import './Chat.css'
import Header from "../Portfolio/components/Header";
import ChatBody from "./components/ChatBody"
const Portfolio=()=>{
return(
	<>
	<Header />
	<ChatBody />
	</>)
}
export default Portfolio