const BlogCard=({data})=>{
	return(
		<>
		 <div className="blog-post-card-1-28-ab">
                    <div className="image-1-19-ab">
                      <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${data.img})` }}>
                        {" "}
                      </div>
                    </div>
                  </div>
		</>
		)
}
export default BlogCard