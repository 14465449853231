import React, {useEffect}  from "react";
import { Link } from "react-router-dom";
import './Portfolio.css'
import ProductList from './components/ProductList'
import Header from "./components/Header";
import plus from '../../Assets/icon/plus.png';
import edit from '../../Assets/icon/edit-03.png';
import user from '../../Assets/icon/Icon.png';
// import content from '../../Assets/icon/Content.png';
import content from '../../Assets/images/Content.png';
import sheraton from "../../Assets/images/Image42.png"
import hilton from "../../Assets/images/Image43.png"
import haile from "../../Assets/images/Image44.png"
import ghion from "../../Assets/images/Image45.png"
import sheraton1 from "../../Assets/service-images/image7.png"
import haile1 from "../../Assets/service-images/image8.png"
import image46 from "../../Assets/images/Image46.png"
import image47 from "../../Assets/images/Image47.png"
import image48 from "../../Assets/images/Image48.png"
import image49 from "../../Assets/images/Image49.png"
import { getUserProfile, product, packages } from './api/portfolio'
const ViewPortfolio=()=>{
  const cUser=JSON.parse(localStorage.getItem('Tuser'))
  const [vendor, setVendor]=React.useState({})
  const [productList, setProductList]=React.useState([])
  const [packageList, setPackageList]=React.useState([])
  const loadProfile = async() => {
    const id=cUser['id']
    const result = await getUserProfile(id);
    setVendor(result['data']['user']);
}
  const loadPackage = async() => {
    const id=cUser['id']
    const user={userId:id}
    const result = await packages(user);
    setPackageList(result['data']['package']);
}
  const loadProduct = async() => {
    const id=cUser['id']
    const user={userId:id}
    const result = await product(user);
    setProductList(result['data']['product']);
}
useEffect(() => {
  loadProfile();
  loadPackage();
  loadProduct();
  }, []);
return(
	<>
	<Header />
	<div className="portfolio_container">
	<div className="top_section">
	<div className="left_top_section">
	<div className="user_logo">
		<img src={vendor.avatar} className="vendor__pp" alt="logo" />
		{/*<img src={user} className="logo__header user_ic" alt="logo" />*/}
		{/*<img src={edit} className="logo__header edit_ic" alt="logo" />*/}
		</div>
		<div className="left_content">
		<div className="portfolio_title">{vendor.organizationName}</div>
		<div className="portfolio_desc">{vendor.tagline}</div>
		</div>
	</div>
	<div className="right_top_section">
	<Link to="/portfolio/edit" className="header_link">
    <div className="menu__button">
        <span className="menu__text">
                          {"Edit Profile"}
        </span>
        </div></Link></div>
	</div>
	<div className="image_container">
	<div className="card_container">
	<div className="card_one">
		<img src={vendor.medias?.split(",")[0]} className="card_img" alt="logo" />
	</div>
	<div className="card_two">
		<img src={vendor.medias?.split(",")[0]} className="card_img" alt="logo" />
	</div>
	<div className="card_three">
		<div className="card_three_one">
			<img src={vendor.medias?.split(",")[0]} className="card_img" alt="logo" />
		</div>
		<div className="card_three_two">
			<img src={vendor.medias?.split(",")[0]} className="card_img" alt="logo" />
		</div>
	</div>
	<div className="card_four">
		<img src={vendor.medias?.split(",")[0]} className="card_img" alt="logo" />
	</div>
	</div>
	</div>
	<div className="about_continer">
	<div className="about_left">
		<span className="card__title">About</span>
	<div className="card_description">{vendor.about}</div>
	</div>
	<div className="about_right">
	<div className="input_fields">
		<span className="text_lable">Location</span>
		<div className="card_description">{vendor.about}</div>
	</div>
	<div className="input_fields">
		<span className="text_lable">Website</span>
		<div className="card_description">{vendor.website}</div>
	</div>
	<div className="input_fields">
		<span className="text_lable">Instagram</span>
		<div className="card_description">{vendor.instagram}</div>
	</div>
	<div className="input_fields">
		<span className="text_lable">Phone no</span>
		<div className="card_description">{vendor.phoneNo}</div>
	</div>
	</div>
	</div>
	<div className="service_view_container">
	<div  className="left_service">
		<div className="card__title">Product or services</div>
		<div className="content-1-1">
                 {productList.slice(0,3).map(x => ( 
                  <ProductList
                  data={x} />
                  ))}
                  </div>
	</div>
	<div className="right_service">
		<div className="card__title">Product package</div>
		
		<div className="portfolio_content-1-1">
                 {packageList.slice(0,3).map(x => ( 
                  <ProductList
                  data={x} />
                  ))}
                  </div>
	</div>
	</div>
	</div>
	</>)
}
export default ViewPortfolio