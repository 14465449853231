import React  from "react";
import "./Vendor.css";
import Header from "../Portfolio/components/Header";
import Blog from "./components/Blog"
const LoggedInVendor=()=>{
	return(
    <>
    <Header />
    <Blog />
    </>
		)
}
export default LoggedInVendor