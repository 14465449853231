import React from "react";
import { Link } from "react-router-dom";
import CommonHeader from "./CommonHeader";
const BudgetHeader =()=>{
	return (
		<>
       <CommonHeader />
		<div className="container budget-header">
		 <div className="group-1-5">
              <div className="vector-1-1584">
                <div className="nodeBg-I19043415_1762592"></div>
              </div>
              <div className="vector-2-530">
                <div className="nodeBg-I19043415_1762593"></div>
              </div>
            </div>

            <div className="group-2-3416">
              <div className="vector-1-3234">
                <div className="nodeBg-I19043415_1765440"></div>
              </div>
              <div className="vector-2-3783">
                <div className="nodeBg-I19043415_1765441"></div>
              </div>
            </div>
            <div className="group-3-2303">
              <div className="vector-1-540">
                <div className="nodeBg-I19043415_1765449"></div>
              </div>
              <div className="vector-2-3612">
                <div className="nodeBg-I19043415_1765450"></div>
              </div>
            </div>
            <div className="group-4-1638">
              <div className="vector-1-851">
                <div className="nodeBg-I19043415_1765443"></div>
              </div>
              <div className="vector-2-216">
                <div className="nodeBg-I19043415_1765444"></div>
              </div>
            </div>
		</div>
		</>
		)
}
export default BudgetHeader;