import React, {useEffect}  from "react";
import BlogCard from './BlogCard'
import image26 from "../../../Assets/images/Image26.png"
import image27 from "../../../Assets/images/Image27.png"
import image28 from "../../../Assets/images/Image28.png"
import image29 from "../../../Assets/images/Image29.png"
import image30 from "../../../Assets/images/Image30.png"
import image34 from "../../../Assets/images/Image34.png"
import image35 from "../../../Assets/images/Image35.png"
import image36 from "../../../Assets/images/Image36.png"
import image37 from "../../../Assets/images/Image37.png"
import image38 from "../../../Assets/images/Image38.png"
import image39 from "../../../Assets/images/Image39.png"
import image40 from "../../../Assets/images/Image40.png"
import image41 from "../../../Assets/images/Image41.png"
import { productList } from '../api/Vendor'
const Blog=()=>{
 
const [jewelleryList, setJewelleryList]=React.useState([])
const [carList, setCarList]=React.useState([])
const [dressList, setDressList]=React.useState([])
const [beautyList, setBeautyList]=React.useState([])
const [videographyList, setVideographyList]=React.useState([])
const loadJewelers = async() => {
    const products={category:'Jewellery'}
    const result = await productList(products);
    setJewelleryList(result['data']['product']);
}
const loadCarRental = async() => {
    const products={category:'Car'}
    const result = await productList(products);
    setCarList(result['data']['product']);
}
const loadDress = async() => {
    const products={category:'Dress'}
    const result = await productList(products);
    setDressList(result['data']['product']);
}
const loadBeauty = async() => {
    const products={category:'Beauty'}
    const result = await productList(products);
    setBeautyList(result['data']['product']);
}
const loadVideography = async() => {
    const products={category:'Videography'}
    const result = await productList(products);
    console.log(result['data']['product'])
    setVideographyList(result['data']['product']);
}

useEffect(() => {
  loadJewelers();
  loadCarRental();
  loadDress();
  loadBeauty();
  loadVideography();
  }, []);
  return(
    <>
    <div className="blog__container">
    <div className="search__blog">
    <input type="text" placeholder="search" className="input__blog"/>
    <div className="buttonsbutton-vd">
      <div className="icon--searchout-1-vd">
        <div className="nodeBg-I19043600_3471597214_3231752"></div>
      </div>
    </div>
    </div>
    <div className="blog__content">
    <div className="blog__title">Jewelers</div>
    <div className="blog__cards">
    {jewelleryList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
    
    </div>
    <div className="blog__title">Car rental</div>
    <div className="blog__cards">
    {carList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
    </div>
    <div className="blog__title">Wedding dress</div>
    <div className="blog__cards">
    {dressList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
    </div>
    <div className="blog__title">Makeup</div>
    <div className="blog__cards">
    {beautyList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
    </div>
    <div className="blog__title">Videography</div>
    <div className="blog__cards">
    {videographyList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
    </div>
    </div>
    </div>
    </>
    )
}
export default Blog