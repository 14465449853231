import "./Home.css";
import Header from "./components/Header";
import Blog from "./components/Blog"
import CategoryBlog from './components/CategoryBlog'

const Home=()=>{
	return(
		<>
		<Header/>
		<Blog/>
		<CategoryBlog/>
		</>
		)
}
export default Home