const MessageDetail=()=>{
	return(
		<div className="message_detail_container">
		<div className="message_left">
		<div className="message_left_profile">
		<span className="message_left_user">Moges Lema</span>
		<span className="message_left_date">Friday 2:20 PM</span>
		</div>
		<span className="message_left_message"> Good Morning What can i do for you?</span>
		</div>
		<div className="message_right">
		<div className="message_left_profile">
		<span className="message_left_user">You</span>
		<span className="message_left_date">Friday 2:20 PM</span>
		</div>
		<span className="message_right_message"> I need a photographer for my wedding and reception</span>
		</div>
		<div className="message_left">
		<div className="message_left_profile">
		<span className="message_left_user">Moges Lema</span>
		<span className="message_left_date">Friday 2:20 PM</span>
		</div>
		<span className="message_left_message"> Do you want to know my rates?</span>
		</div>
		<div className="message_right">
		<div className="message_left_profile">
		<span className="message_left_user">You</span>
		<span className="message_left_date">Friday 2:20 PM</span>
		</div>
		<span className="message_right_message"> Definitely</span>
		</div>
		</div>
		)
}
export default MessageDetail