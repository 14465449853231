import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
const Header =()=>{
	return (
		<>
		<div className="vendor_header">
		<div className="vendor_left__header">
		<img src={logo} className="vendor_logo__header" alt="logo" />
    	<Link to="/" className="vendor_header_link"><div className="vendor_menu__left">Home</div></Link>
		<Link to="/vendor" className="vendor_header_link"><div className="vendor_menu__left">Vendor</div></Link>
		</div>
   		 <div className="vendor_middle_header">
   		 <Link to="/about" className="vendor_header_link"><div className="vendor_menu__left">About Us</div></Link>
   		 </div>
		<div className="vendor_right__header">
		<Link to="/login" className="vendor_header_link"><div className="vendor_menu__right">Login</div></Link>
		<div className="vendor_menu__right menu__button">
		<span className="vendor_menu__text">
                          {"Get Started Now!"}
    </span>
    </div>
		</div>
		</div>
		</>
		)
}
export default Header;