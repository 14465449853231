import './Portfolio.css'
import Header from "./components/Header";
import EditPage from "./components/EditPage";
const Portfolio=()=>{
return(
	<>
	<Header />
	<EditPage />
	</>)
}
export default Portfolio