import React, {useEffect}  from "react";
import logo from '../../../Assets/logo/Tilosh.png';
import sheraton from "../../../Assets/service-images/image3.png"
import user from '../../../Assets/icon/Icon.png';
import x from '../../../Assets/icon/x.png'
import { Link } from "react-router-dom";
import { createProduct } from '../api/Vendor'
const Product=({ isOpen, onClose })=>{
  	const cUser=JSON.parse(localStorage.getItem('Tuser'))
  	console.log()
    const imageRef = React.useRef();
    const [productName, setProductName]=React.useState('')
    const [description, setDescription]=React.useState('')
    const [category, setCategory] = React.useState('');
    const [price, setPrice]=React.useState('')
    const [productType, setProductType]=React.useState('')
    const [fileList,setFileLlist]=React.useState([])
    const [file,setFile]=React.useState()
  	const handleChange = (event) => {
  	const files = Array.from(event.target.files);
  	const fileUrls = files.map((f) => URL.createObjectURL(f));
	setFileLlist(fileUrls);
    setFile(event.target.files);   
  	}
    const updateProfile =()=>{
    imageRef.current.click();
 	 };

	  const deleteUrl = (index) => {
	  	const values={...file}
	  	delete values[index];
	  	setFile(values)
	    setFileLlist((prevUrl) =>
	      prevUrl.filter((_, i) =>i !== index)
	    );
	  };
	  const addProduct=async()=>{
	    const formData = new FormData();
	    formData.append("productName",productName);
	    formData.append("userId",cUser['id']);
	    formData.append("category",category);
	    formData.append("description",description);
	    formData.append("price",price);
	    formData.append("productType",productType);
	    let key;
	    for (key of Object.keys(file)) {
	           formData.append("medias", file[key]);
	    }
	    const data = await createProduct(formData);
	    onClose()
	  }
    if (!isOpen) return null;
	return(
    <div className="modal_container">
		<div className="signup_continer_s">
		<div className="package_modal">
		<div className="package_form">
		<div className="top_package">
		<div className="package_title">
		New Product or Service
		</div>
		<img  onClick={onClose} src={x} className="logo__header package_close" alt="logo" />
		</div>
		<div className="input_fields">
		<span className="text_lable">Product or Service name</span>
		<input type="text"
		value={productName}
	    onChange={(e)=>setProductName(e.target.value)}
	    className="input_field"/>
		</div>
		<div className="input_fields">
		<span className="text_lable">Product Category</span>
		<select className="select__category"
        value={category}
        onChange={e => setCategory(e.target.value)}
        >
			<option hidden>Select Product Category</option>
		  {cUser['industry'].split(',').map((item)=>(
		  	<option key={item} value={item}>{item}</option>
		  	))}
		</select>
		</div>
		<div className="input_fields">
		<span className="text_lable">Description</span>
		<textarea rows="3" 
		value={description}
	    onChange={(e)=>setDescription(e.target.value)}
	    className="textarea_field"></textarea>
		</div>
		<div className="input_fields">
		<span className="text_lable">Price</span>
		<input type="text" 
		value={price}
	    onChange={(e)=>setPrice(e.target.value)}
	    className="input_field"/>
		</div>
		<div className="input_radios">
		<div className="input_radio">
	  <label for="age1">Sell</label>
		<input type="radio" id="age1" name="age" onClick={() =>setProductType('sell')} value="sell"/>
	  </div>
	  <div className="input_radio">
	  <label for="age2">Rent</label>  
	  <input type="radio" id="age2" name="age" onClick={() =>setProductType('rent')} value="rent"/>
	  </div>
		</div>
		<div className="input_fields" onClick={updateProfile}>
		 <input
          ref={imageRef}
          type="file"
          multiple="multiple"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange}
        />
		<span className="text_lable">Add Media</span>
		<div className="input_media">
		<img src={user} className="logo__header package_img" alt="logo" />
		<div className="package_detail">Drag & drop or click to upload </div>
		</div>
		</div>
		<div className="images_list">
        {(fileList || []).map((url,index) => (
          <div style={{position: 'relative', width:'30%'}}>
          <img src={url} className="image_card" alt="..." key={url} />
          <img  onClick={()=>deleteUrl(index)} src={x} className="image_close package_close" alt="logo" />
          </div>
        ))}
		</div>
		<div onClick={addProduct} className="package_button">Save</div>
		</div>
		</div>
		</div>
		</div>

		)
}
export default Product

