import React from "react";
import { Link } from "react-router-dom";
import Header from "./CommonHeader";
const BGHeader =()=>{
  return (
    <>
    <Header />
    <div className="container">
     <div className="group-1-5">
              <div className="vector-1-1584">
                <div className="nodeBg-I19043415_1762592"></div>
              </div>
              <div className="vector-2-530">
                <div className="nodeBg-I19043415_1762593"></div>
              </div>
            </div>

            <div className="group-2-3416">
              <div className="vector-1-3234">
                <div className="nodeBg-I19043415_1765440"></div>
              </div>
              <div className="vector-2-3783">
                <div className="nodeBg-I19043415_1765441"></div>
              </div>
            </div>
            <div className="group-3-2303">
              <div className="vector-1-540">
                <div className="nodeBg-I19043415_1765449"></div>
              </div>
              <div className="vector-2-3612">
                <div className="nodeBg-I19043415_1765450"></div>
              </div>
            </div>
            <div className="group-4-1638">
              <div className="vector-1-851">
                <div className="nodeBg-I19043415_1765443"></div>
              </div>
              <div className="vector-2-216">
                <div className="nodeBg-I19043415_1765444"></div>
              </div>
            </div>
          <div className="frame-h">
          <div className="image-1-h">
                <div className="rectangle-1-1-h">
                  <div className="nodeBg-I19043415_1751668">
                    {" "}
                  </div>
                </div>
                <div className="rectangle-2-1-148">
                  <div className="nodeBg-I19043415_1751669">
                    {" "}
                  </div>
                </div>
              </div>
              <div className="content-1-bg">
                <div className="frame-10">
                  <div className="heading-1">
                    <span className="heading-1-2856-0">
                      {"Finding the best event vendors "}
                    </span>
                  </div>
                  <div className="supporting-text-1-656">
                    <span className="supporting-text-1-656-0">
                      {
                        "Discover the perfect wedding and event vendors for your special day!"
                      }
                    </span>
                  </div>
                </div>
                <div className="search_bar">
                        <div className="input-field-1">
                            <div className="input-1-14-bg">
                                <div className="text-input-1-8">
                                    <span className="leading-text-1-2808-0">
                                      {"$"}
                                    </span>
                                    <span className="text-1-792-0">
                                      {"200k - 300k"}
                                    </span>
                                </div>
                            <div className="dropdown-1-1353">
                                <div className="icon-1-3834">
                                  <div className="nodeBg-I19043415_1751641_3285380151_3463404534"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                  <div className="buttonsbutton-1-35bv">
                  <Link to="/bg/event-budget" className="header_link">
                      <div className="text-1-h">
                        <span className="text-1-4620-0">{"Change Event Budget"}</span>
                      </div>
                      </Link>
                    </div>
                    </div>
                  </div>
                  </div>
    </div>
    </>
    )
}
export default BGHeader;