import { Link } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
import notfication from '../../../Assets/icon/Icon1.png';
import user from '../../../Assets/icon/Icon.png';
const Header=()=>{
	return(
		<>
		<div className="Header">
		<div className="left__header">
		<Link to="/home" className="header_link"><img src={logo} className="logo__header" alt="logo" /></Link>
    <Link to="/home" className="header_link"><div className="menu__left">Home</div></Link>
    <Link to="/Portfolio" className="header_link"><div className="menu__left">Portfolio</div></Link>
		<Link to="/chat" className="header_link"><div className="menu__left">Chat</div></Link>
		<Link to="/vendors" className="header_link"><div className="menu__left">Vendors</div></Link>
		</div>
		<div className="right__header">
    <Link to="/" className="header_link"><img src={notfication} className="notification__header" alt="logo" /></Link>
    <Link to="/" className="header_link user_icon"><img src={user} className="notification__header" alt="logo" /></Link>
    <Link to="/am" className="header_link">	<div className="menu__right menu__lang">
    
            <div className="translate">  
            <div className="translate__icon">
            </div></div>
             <span className="menu__right">EN</span>
             </div></Link>
    <div className="menu__right">Logout</div>
		</div>
		</div>
		</>
		)
}
export default Header