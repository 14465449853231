const BlogCard=({data})=>{
  const serviceList=['Venue','Cake','Photo&Video','Beauty','Catering','DJ','Decor','Jewellery']
	return(

              <div className="blog-post-card-1-252">
                <div className="image-1-2550">
                  <div className="nodeBg-I19043426_16837556"
                  style={{backgroundImage: `url(${data.img})` }}>
                    {" "}
                  </div>
                  <div className="rate-1-3315">
                    <div className="text-1-3066">
                        <span className="this-is-a-text-1-3188-0">
                          {"16 reviews"}
                        </span>
                    </div>
                    <div className="frame-5-1-833">
                      {[...Array(4)].map(x => (
                        <div className="rate-star-1-2401">
                        <div className="icon--starfille-1-833">
                            <div className="nodeBg-I19043426_16940060_39611242_35172"></div>
                        </div>
                      </div>
                      ))}

                      <div className="rate-star-5-624">
                        <div className="mask-group-1-4361">
                          <div className="nodeBg-I19043426_16940064_39611239">
                            {" "}
                          </div>
                        </div>
                      </div>
                      <div className="text-1-66">
                          <span className="this-is-a-text-1-106-0">
                            {"4.5"}
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-1-2742">
                  <div className="categories-1">
                   {data.tags.map((item)=>(
                     <div className="badge-1-68">
                      <div className="text-1-1873">
                        <span className="text-1-1873-0">{item}</span>
                      </div>
                    </div>
                    ))}
                  </div>
                  <div className="heading-and-tex-1-2542">
                    <div className="olivia-rhye--20-1-2776">
                      <span className="olivia-rhye--20-1-2776-0">
                        {data.location}
                      </span>
                    </div>
                   {/* <div className="supporting-text-1-3599">
                      <span className="supporting-text-1-3599-0">
                        {
                          "How do you create compelling presentations that wow your colleagues and impress your managers?"
                        }
                      </span>
                    </div>*/}
                    <div className="heading-and-ico-1-4900">
                      <div className="heading-1-4692">
                        <span className="heading-1-4692-0">
                          {data.name}
                        </span>
                      </div>
                      <div className="icon-wrap-1-1368">
                        <div className="arrowupright-1-363">
                          <div  className="icon-1-3724">
                            <div className="nodeBg-I19043426_16837563_3463404516"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
}

export default BlogCard;