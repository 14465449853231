import React, {useEffect}  from "react";
import BlogCard from '../../Venue/components/BlogCard'
import velo from "../../../Assets/service-images/image_3.png"
import suit from "../../../Assets/service-images/image_4.png"
import catering from "../../../Assets/service-images/image_5.png"
import photography from "../../../Assets/service-images/image_6.png"
import makeup from "../../../Assets/service-images/image_7.png"
import ring from "../../../Assets/service-images/image_8.png"
import { getProducts } from '../../Vendor/api/Vendor'
const Blog=()=>{
  const [productList, setProductList]=React.useState([])
  const loadProduct = async() => {
      const result = await getProducts();
      setProductList(result['products']);
  }
  useEffect(() => {
    loadProduct();
    }, []);
	return(
		<>
        <div className="blog-section">
            {/*<div className="rectangle-3">
              <div className="nodeBg-19043620">
                {" "}
              </div>
            </div>*/}
            <div className="frame-13-sr">
                      <div className="frame-14">
                        <div className="heading-1-42091">
                          <span className="heading-1-4209-01">
                            {`Recommendations based on budget`}
                          </span>
                        </div>
                        {/*<div className="icon-wrap-1-828">
                            <div className="icon-1-684">
                              <div className="nodeBg-I19043595_3463404543"></div>
                            </div>
                        </div>*/}
                      </div>
                      {/*<div className="email-capture">
                        <div className="input-field-1">
                            <div className="input-1-14">
                                <div className="text-input-1-8">
                                    <span className="leading-text-1-2808-0">
                                      {"$"}
                                    </span>
                                    <span className="text-1-792-0">
                                      {"200k - 300k"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="input-field-2">
                            <div className="input-1-85">
                                    <span className="text-1-20">
                                      {"Venues"}
                                    </span>
                          </div>
                        </div>
                        <div className="buttonsbutton-1">
                          <div className="icon--searchout-1-2890">
                              <div className="nodeBg-I19043600_3471597214_3231752"></div>
                          </div>
                        </div>
                      </div>*/}
                    </div>
                  </div>
               <div className="container-1-988">
                <div className="content-1-1">
                 {productList.map(x => ( 
                  <BlogCard
                  data={x} />
                  ))}
                  </div>
                  </div>
                  </>
            )
}
export default Blog