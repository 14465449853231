import React from "react";
import StarFilled from '../../../Assets/icon/StarFilled.png';
const EventBudgetCard=({name})=>{
  return(
    <>
      <div className="budget__card">
      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
      <div className="budget__card__title">
      {name}
      </div>
      <div className="budget__quantitys">
      <div className="budget__minus">-</div>
      <div className="budget__quantity">1</div>
      <div className="budget__plus">+</div>
      </div>
      </div>
    </>
    )
}
const EventBudgetDetail=()=>{
  const[amount, setAmount]=React.useState('')
  const[vello, setVello]=React.useState(0)
  const[suit, setSuit]=React.useState(0)
  const[bridesMaids, setBridesMaids]=React.useState(0)
  const[bestMan, setBestMan]=React.useState(0)
  const[mainHall, setMainHall]=React.useState(0)
  const[reception, setReception]=React.useState(0)
  const[catering, setCatering]=React.useState(0)
  const[photographerCar, setPhotographerCar]=React.useState(0)
  const[limousine, setLimousine]=React.useState(0)
	return(
		<>
        <div className="blog-section">
            <div className="frame-13-sr">
                      <div className="frame-14-event">
                        <div className="heading-1-42091ev">
                          <span className="heading-1-4209-ev">
                            {`Event Budget`}
                          </span>
                        </div>
                        <p className="budget__detail">Please give us your budget and services you 
                        require for the event so that we can create <br/>a personalized 
                        recommendation for you</p>
                      </div>
                    </div>
                    <div className="budget__container">
                    <div className="budget__dropdown">
                    <label className="dropdown__label" for="language">Budget:</label>
                    <select className="dropdown__select" onChange={(e)=>setAmount(e.target.value)} 
                            value={amount} name="language" id="language">
                      <option value="100k-200k">100k-200k Br</option>
                      <option value="200k-300k" selected>200k-300k Br</option>
                      <option value="300k-400k">300k-400k Br</option>
                      <option value="400k-100k">400k-100k Br</option>
                    </select>
                    </div>
                    <div className="dropdown__text"><img src={StarFilled} className="notification__header" alt="logo" />
                    <p className="budget__dropdown__text">For setting the priority of the item</p></div>
                    </div>
                    <div className="budget__section">
                    <div className="budget__title">Clothing</div>
                    <div className="budget__cards">

                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Vello
                      </div>
                      <div className="budget__quantitys">
                      <div className={(vello==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setVello(vello-1)}>-</div>
                      <div className="budget__quantity">{vello}</div>
                      <div className="budget__plus" onClick={()=>setVello(vello+1)}>+</div>
                      </div>
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Suit
                      </div>
                      <div className="budget__quantitys">
                      <div className={(suit==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setSuit(suit-1)}>-</div>
                      <div className="budget__quantity">{suit}</div>
                      <div className="budget__plus" onClick={()=>setSuit(suit+1)}>+</div>
                      </div>
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Bridesmaids
                      </div>
                      <div className="budget__quantitys">
                      <div className={(bridesMaids==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setBridesMaids(bridesMaids-1)}>-</div>
                      <div className="budget__quantity">{bridesMaids}</div>
                      <div className="budget__plus" onClick={()=>setBridesMaids(bridesMaids+1)}>+</div>
                      </div>
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Best Man
                      </div>
                      <div className="budget__quantitys">
                      <div className={(bestMan==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setBestMan(bestMan-1)}>-</div>
                      <div className="budget__quantity">{bestMan}</div>
                      <div className="budget__plus" onClick={()=>setBestMan(bestMan+1)}>+</div>
                      </div>
                      </div>
                    </div>
                    </div>
                    <div className="budget__section">
                    <div className="budget__title">Venue</div>
                    <div className="budget__cards">
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Main Hall
                      </div>
                      <div className="budget__quantitys">
                      <div className={(mainHall==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setMainHall(mainHall-1)}>-</div>
                      <div className="budget__quantity">{}</div>
                      <div className="budget__plus" onClick={()=>setMainHall(mainHall+1)}>+</div>
                      </div>mainHall
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Reception
                      </div>
                      <div className="budget__quantitys">
                      <div className={(reception==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setReception(reception-1)}>-</div>
                      <div className="budget__quantity">{reception}</div>
                      <div className="budget__plus" onClick={()=>setReception(reception+1)}>+</div>
                      </div>
                      </div>
                    </div>
                    </div>
                    <div className="budget__section">
                    <div className="budget__title">Food & Transportation</div>
                    <div className="budget__cards">
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Catering
                      </div>
                      <div className="budget__quantitys">
                      <div className={(catering==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setCatering(catering-1)}>-</div>
                      <div className="budget__quantity">{catering}</div>
                      <div className="budget__plus" onClick={()=>setCatering(catering+1)}>+</div>
                      </div>
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Photographer Car
                      </div>
                      <div className="budget__quantitys">
                      <div className={(photographerCar==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setPhotographerCar(photographerCar-1)}>-</div>
                      <div className="budget__quantity">{photographerCar}</div>
                      <div className="budget__plus" onClick={()=>setPhotographerCar(photographerCar+1)}>+</div>
                      </div>
                      </div>
                      <div className="budget__card">
                      <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                      <div className="budget__card__title">
                        Limousine
                      </div>
                      <div className="budget__quantitys">
                      <div className={(limousine==0) ? 'budget__minus is-disabled' : 'budget__minus'}
                       onClick={()=>setLimousine(limousine-1)}>-</div>
                      <div className="budget__quantity">{limousine}</div>
                      <div className="budget__plus" onClick={()=>setLimousine(limousine+1)}>+</div>
                      </div>
                      </div>
                    </div>
                    </div>
                    <div className="budget__buton">
                    <div className="package_button">Save</div>
                    <div className="package_button cancel__button">Cancel</div>
                    </div>
                  </div>
                  </>
            )
}
export default EventBudgetDetail