const ProductList=({data})=>{
  const cUser=JSON.parse(localStorage.getItem('Tuser'))
	return(
                  <div className="blog-post-card-1-28">
                    <div className="image-1-19">
                      <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${data.medias?.split(",")[0]})` }}>
                        {" "}
                      </div>
                      <div className="rate-1-320">
                      </div>
                    </div>
                    <div className="content-1-322">
                        <div className="olivia-rhye--20-1-1175">
                          <span className="olivia-rhye--20-1-1175-0">
                            {cUser['organizationName']}&nbsp;{"|"}  &nbsp;
                            {data.price}&nbsp; BR
                          </span>
                        </div>
                        <div className="heading-and-ico-1-663">
                          <div className="heading-1-160">
                            <span className="heading-1-160-0">
                              {data.productName || data.packageName}
                            </span>
                          </div>
                          <div className="icon-wrap-1-582">
                              <div className="icon-1-296">
                                <div className="nodeBg-I19043604_18532101_3463404516"></div>
                              </div>
                            </div>
                        </div>
                        <div className="supporting-text-1-2849">
                          <span className="supporting-text-1-2849-0">
                            {
                              data.description
                            }
                          </span>
                        </div>
                      </div>
                    <div className="buttonsbutton-1-225">
                          <span className="text-1-1-0">{"Add to Cart"}</span>
                    </div>
                  </div>
                  )
}
export default ProductList