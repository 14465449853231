import BlogCard from './BlogCard'
import chala from "../../../Assets/images/image0.png"
import abel from "../../../Assets/images/image1.png"
import yoseph from "../../../Assets/images/image2.png"
const Blog=()=>{
  const cardList=[
  {
    name:'Chala & Bezi',
    location:"Sheraton Addis • Addis Ababa",
    img:chala,
    tags:['Jewellers','Cake','Photo&Video','Beauty','Catering','DJ','Decor']
  },
  {
    name:'Abel & Makeda',
    location:"Sheraton Addis • Addis Ababa",
    img:abel,
    tags:['Venue','Cake','Photo&Video','Beauty','Catering','DJ','Decor','Jewellery']
  },
  {
    name:'Yoseph & Mersilas',
    location:"Sheraton Addis • Addis Ababa",
    img:yoseph,
    tags:['Venue','Cake','Photo&Video','Beauty','Catering','DJ','Decor','Jewellery']
  }]
	return(
		 <div className="blog-section-1-280">
          <div className="container-1-4026">
                  <div className="heading-1-208">
                    <span className="heading-1-208-0">
                      {"Top recent programs"}
                    </span>
                  </div>
          </div>
          <div className="container-2-2220">
          {cardList.map(x => (
            <BlogCard 
            data={x}/>
            ))}
            </div>
          </div>
        )
}
export default Blog;