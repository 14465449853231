const CategoryBigCard=({ largeTitle,largeImg,smallTitle,smallImg})=>{
	return(
            <div className="top-card">
              <div className="blog-post-card-1-3621">
                <div className="image-1-90">
                  <div className="nodeBg-I19043439_1751433"
                   style={{backgroundImage: `url(${largeImg})` }}>
                    {" "}
                  </div>
                </div>
                <div className="content-1-1785">
                        <span className="heading-1-4234-0">{largeTitle}</span>
                </div>
              </div>
              <div className="blog-post-card-2-867">
                <div className="image-1-1479">
                  <div className="nodeBg-I19043440_1751433"
                   style={{backgroundImage: `url(${smallImg})` }}>
                    {" "}
                  </div>
                </div>
                <div className="content-1-108">
                        <span className="heading-1-4275-0">{smallTitle}</span>
                </div>
              </div>
              </div>
              )
}
export default CategoryBigCard