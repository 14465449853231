import React  from "react";
import "../Vendor/Vendor.css";
import Header from "./components/VendorHeader";
import Blog from "../Vendor/components/Blog"
const Vendor=()=>{
	return(
    <>
    <Header />
    <Blog />
    </>
		)
}
export default Vendor