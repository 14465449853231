import React  from "react";
import "./Vendor.css";
import Header from "./components/Header";
import Blog from "./components/Blog"
const Vendor=()=>{
	return(
    <>
    <Header />
    <Blog />
    </>
		)
}
export default Vendor