import BlogCard from './BlogCard'
import chala from "../../../Assets/images/image0.png"
import abel from "../../../Assets/images/image1.png"
import yoseph from "../../../Assets/images/image2.png"
const Blog=()=>{
  const cardList=[
  {
    name:'ጫላ እና ቤዚ',
    location:"ሸራተን አዲስ • አዲስ አበባ",
    img:chala,
    tags:['ቀለበት','ኬክ','ፎቶና ቪዲዮ','ሜካፕ','ምግብ አቅርቦት','ዲጄ','ዶኮር']
  },
  {
    name:'አቤል እና ማክዳ',
    location:"ሸራተን አዲስ • አዲስ አበባ",
    img:abel,
    tags:['አዳራሽ','ኬክ','ፎቶና ቪዲዮ','ሜካፕ','ምግብ አቅርቦት','ዲጄ','ዶኮር','ቀለበት']
  },
  {
    name:'ዮሴፍ እና ማርሲላስ',
    location:"ሸራተን አዲስ • አዲስ አበባ",
    img:yoseph,
    tags:['አዳራሽ','ኬክ','ፎቶና ቪዲዮ','ሜካፕ','ምግብ አቅርቦት','ዲጄ','ዶኮር','ቀለበት']
  }]
	return(
		 <div className="blog-section-1-280">
          <div className="container-1-4026">
                  <div className="heading-1-208">
                    <span className="heading-1-208-0">
                      {"በእኛ አንደኛ የሆኑ ፕሮግራሞች"}
                    </span>
                  </div>
          </div>
          <div className="container-2-2220">
          {cardList.map(x => (
            <BlogCard 
            data={x}/>
            ))}
            </div>
          </div>
        )
}
export default Blog;