import MessageList from './MessageList'
import MessageDetail from './MessageDetail'
import notfication from '../../../Assets/icon/Icon1.png';
const ChatBody=()=>{
	const chatList=[
	{
		name:'Moges Lemma',
		profession:'Photographer',
		message:'Welcome to Tilosh, your premier destination for seamless wedding planning'
	},
	{
		name:'Abdu Seid',
		profession:'Event Organizor',
		message:'Welcome to Tilosh, your premier destination for seamless wedding planning'
	},
	{
		name:'Sara Solomon',
		profession:'Wedding Cars',
		message:'Welcome to Tilosh, your premier destination for seamless wedding planning'
	},
	{
		name:'Sara Solomon',
		profession:'Wedding Cars',
		message:'Welcome to Tilosh, your premier destination for seamless wedding planning'
	},
	{
		name:'Sara Solomon',
		profession:'Wedding Cars',
		message:'Welcome to Tilosh, your premier destination for seamless wedding planning'
	}]
	return(
		<div className="chat_container">
		 <span className="heading-1-1254-0"> {"My Chats"} </span>
		 <div className="chat_top">
		 <span className="portfolio_title">Clients</span>
		 <span className="portfolio_title">Vendors</span>
		 </div>
		 <div className="chat_content">
		 <div className="left_chat">
		 	<div className="left_status">
		 		<span className="portfolio_title active_status">Active</span>
		 		<span className="portfolio_title">Closed</span>
		 	</div>
		 	<div className="left_show">
		 		<span className="show_names">Show:&nbsp;</span>
		 		<span className="show_names active_names">Inbox&nbsp;{"|"}  &nbsp;</span>
		 		<span className="show_names">Unread&nbsp;{"|"}  &nbsp;</span>
		 		<span className="show_names">Read&nbsp;{"|"}  &nbsp;</span>
		 		<span className="show_names">Sent</span>
		 	</div>
		 	<div className="message_lists">
		 		 {chatList.map(x => ( 
                  <MessageList
                  data={x} />
                  ))}
		 	</div>
		 </div>
		 <div className="right_chat">
		 	<div className="message_detail_title">Message Detail</div>
		 	<MessageDetail />
		 	<div className="send_message">
		 	<input className="message_input" type="text" placeholder="Message"/>
		 	<img src={notfication} className="logo__header" alt="logo" />
		 	</div>
		 </div>
		 </div>
		</div>
		)
}
export default ChatBody