import React from "react";
import { useNavigate } from "react-router-dom";
import plus from '../../../Assets/icon/plus.png';
import edit from '../../../Assets/icon/edit-03.png';
import user from '../../../Assets/icon/Icon.png';
import content from '../../../Assets/icon/Content.png';
import Package from "../../Vendor/components/Package";
import Product from "../../Vendor/components/Product";
import { updateUser } from '../api/portfolio'
const EditPage=()=>{
	let navigate = useNavigate(); 
	const cUser=JSON.parse(localStorage.getItem('Tuser'))
    const [packageOpen, setPackageOpen] = React.useState(false);
    const [productOpen, setProductOpen] = React.useState(false);
    const imageRef = React.useRef();
    const imageRef1 = React.useRef();
    const imageRef2 = React.useRef();
    const imageRef3 = React.useRef();
    const imageRef4 = React.useRef();
    const imageRef5 = React.useRef();
    const [image, setImage] = React.useState("");
    const [about, setAbout] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [website, setWebsite] = React.useState("");
    const [instagram, setInstagram] = React.useState("");
    const [facebook, setFacebook] = React.useState("");
    const [phoneNo, setPhoneNo] = React.useState("");
    const [media1, setMedia1] = React.useState("");
    const [media2, setMedia2] = React.useState("");
    const [media3, setMedia3] = React.useState("");
    const [media4, setMedia4] = React.useState("");
    const [media5, setMedia5] = React.useState("");
    const [avatar, setAvatar]=React.useState('');
    const [medias, setMedias]=React.useState([null,null,null,null,null])
 
    const handlePackageClose = () => {
        setPackageOpen(false);
    };
 
    const handlePackageOpen = () => {
        setPackageOpen(true);
    };

    const handleProductClose = () => {
        setProductOpen(false);
    };
 
    const handleProductOpen = () => {
        setProductOpen(true);
    };
    const updateProfile =()=>{
    imageRef.current.click();
 	 };
 	 const updateProfile1=()=>imageRef1.current.click();
 	 const updateProfile2=()=>imageRef2.current.click();
 	 const updateProfile3=()=>imageRef3.current.click();
 	 const updateProfile4=()=>imageRef4.current.click();
 	 const updateProfile5=()=>imageRef5.current.click();

  	const handleChange = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    setAvatar(event.target.files)
    setImage(fileUrl);
  	}
  	const handleChange1 = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    let files=[...medias]
    files.splice(0, 1, event.target.files[0]);
    setMedias(files)
    setMedia1(fileUrl);
  	}
  	const handleChange2 = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    let files=[...medias]
    files.splice(1, 1, event.target.files[0]);
    setMedias(files)
    setMedia2(fileUrl);
  	}
  	const handleChange3 = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    let files=[...medias]
    files.splice(2, 1, event.target.files[0]);
    setMedias(files)
    setMedia3(fileUrl);
  	}
  	const handleChange4 = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    let files=[...medias]
    files.splice(3, 1, event.target.files[0]);
    setMedias(files)
    setMedia4(fileUrl);
  	}
  	const handleChange5 = (event) => {
    const fileUrl =URL.createObjectURL(event.target.files[0]);
    let files=[...medias]
    files.splice(4, 1, event.target.files[0]);
    setMedias(files)
    setMedia5(fileUrl);
  	}
  	const updateUserProfile=async()=>{
  	const id=cUser['id']
    const formData = new FormData();
    formData.append("about",about);
    formData.append("location",location);
    formData.append("website",website);
    formData.append("phoneNo",phoneNo);
    formData.append("instagram",instagram);
    formData.append("facebook",facebook);
    // formData.append("avatar",avatar);
    // formData.append("medias",medias);
    let key;
    medias.forEach((item) => {
           formData.append("medias", item);
	});
    for (key of Object.keys(avatar)) {
           formData.append("avatar", avatar[key]);
    }
    const data = await updateUser(id,formData);
	  let path = `/home`; 
      navigate(path);
  	}
  	const cancelProfile=()=>{
	  let path = `/home`; 
      navigate(path);
  	}
return(
	<>
	<div className="portfolio_container">
	<div className="top_section">
	<div className="left_top_section">
	<div className="user_logo" onClick={updateProfile}>
        <input
          ref={imageRef}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange}
        />
        {image && <img src={image} className="vendor__pp" alt="logo" />}
		{!image && (<><img src={content} className="logo__header" alt="logo" />
		<img src={user} className="logo__header user_ic" alt="logo" />
		<img src={edit} className="logo__header edit_ic" alt="logo" /></>)}
		</div>
		<div className="left_content">
		<div className="portfolio_title">Ambassador Suits</div>
		<div className="portfolio_desc">Addis Ababa based wedding suits and vello rent</div>
		</div>
	</div>
	<div className="right_top_section">
	<div className="menu__button cancel_button">
		<span onClick={cancelProfile} className="menu__text cancel_text">
                          {"Cancel"}
        </span>
        </div><div className="menu__button">
		<span onClick={updateUserProfile} className="menu__text">
                          {"Save Profile"}
        </span>
        </div></div>
	</div>
	<div className="image_container">
	<div className="card_container">
	<div className="card_one" onClick={updateProfile1}>
		 <input
          ref={imageRef1}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange1}
        />
	 {media1 && <img src={media1} className="media__style" alt="logo" />}
	 {!media1 && (<>
	 <img src={plus} className="logo__header plus_ic" alt="logo" />
	 <div className="card_media">Add media</div> </>)}
		
	</div>
	<div className="card_two" onClick={updateProfile2}>
	 {media2 && <img src={media2} className="media__style" alt="logo" />}
	 {!media2 && (<>
	 <img src={plus} className="logo__header plus_ic" alt="logo" />
	 <div className="card_media">Add media</div> </>)}
		 <input
          ref={imageRef2}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange2}
        />
	</div>
	<div className="card_three">
		<div className="card_three_one" onClick={updateProfile3}>
	 {media3 && <img src={media3} className="media__style" alt="logo" />}
	 {!media3 && (<>
	 <img src={plus} className="logo__header plus_ic" alt="logo" />
	 <div className="card_media">Add media</div> </>)}
			<input
          ref={imageRef3}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange3}
        />
		</div>
		<div className="card_three_two" onClick={updateProfile4}>
	 {media4 && <img src={media4} className="media__style" alt="logo" />}
	 {!media4 && (<>
	 <img src={plus} className="logo__header plus_ic" alt="logo" />
	 <div className="card_media">Add media</div> </>)}
			 <input
          ref={imageRef4}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange4}
        />
		</div>
	</div>
	<div className="card_four" onClick={updateProfile5}>
	 {media5 && <img src={media5} className="media__style" alt="logo" />}
	 {!media5 && (<>
	 <img src={plus} className="logo__header plus_ic" alt="logo" />
	 <div className="card_media">Add media</div> </>)}
		 <input
          ref={imageRef5}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleChange5}
        />
	</div>
	</div>
	</div>
	<div className="about_continer">
	<div className="about_left">
		<span className="card__title">About me</span>
	<textarea rows="3"
	value={about}
    onChange={(e)=>setAbout(e.target.value)}
    className="about_textarea_field"></textarea>
	</div>
	<div className="about_right">
	<div className="input_fields">
		<span className="text_lable">Location</span>
		<input type="text" 
	value={location}
    onChange={(e)=>setLocation(e.target.value)}
    className="input_field"/>
	</div>
	<div className="input_fields">
		<span className="text_lable">Website</span>
		<input type="text" 
	value={website}
    onChange={(e)=>setWebsite(e.target.value)}
    className="input_field"/>
	</div>
	<div className="input_fields">
		<span className="text_lable">Instagram</span>
		<input type="text" 
	value={instagram}
    onChange={(e)=>setInstagram(e.target.value)}
    className="input_field"/>
	</div>
	<div className="input_fields">
		<span className="text_lable">Facebook</span>
		<input type="text" 
	value={facebook}
    onChange={(e)=>setFacebook(e.target.value)}
    className="input_field"/>
	</div>
	<div className="input_fields">
		<span className="text_lable">Phone no</span>
		<input type="text" 
	value={phoneNo}
    onChange={(e)=>setPhoneNo(e.target.value)}
    className="input_field"/>
	</div>
	</div>
	</div>
	<div className="service_container">
	<div  className="left_service">
		<div className="card__title">Product or services</div>
		<div onClick={handleProductOpen} className="left_service_card">
		<img src={plus} className="logo__header plus_ic" alt="logo" />
		<div className="product_card">Create a product or service</div>
		</div>
	</div>
	<div className="right_service">
		<div className="card__title">Product package</div>
		<div onClick={handlePackageOpen} className="left_service_card">
		<img src={plus} className="logo__header plus_ic" alt="logo" />
		<div className="package_card">Create package</div>
		</div>
	</div>
	</div>
	</div>
	  <Package isOpen={packageOpen} onClose={handlePackageClose} />
	  <Product isOpen={productOpen} onClose={handleProductClose} />
	</>
	) 
}
export default EditPage 