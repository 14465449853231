const CategorySmallCards=({data})=>{
	return(
              <div className="blog-post-card-3-9">
                <div className="image-1-1927">
                  <div className="nodeBg-I19043441_1751433"
                  style={{backgroundImage: `url(${data.img})` }}>
                    {" "}
                  </div>
                </div>
                <div className="content-1-4506">
                        <span className="heading-1-90-0">
                          {data.title}
                        </span>
                </div>
              </div>
		)
}
export default CategorySmallCards