import React, {useEffect}  from "react";
import {cart} from '../../Services/api/services'
import { getUserProfile } from '../../Vendor/api/Vendor'
const BlogCard=({data})=>{
  const [user, setUser]=React.useState({})
  const cUser=JSON.parse(localStorage.getItem('Tuser'))
  const loadUser = async() => {
      const result = await getUserProfile(cUser['id']);
      setUser(result['data']['user']);
  }
  const addToCart=async(productId)=>{
   const data={
      productId:productId,
      userId:cUser['id'],
      status:'pending'
    }
    const result = await cart(data);
  }
  useEffect(() => {
    loadUser();
    }, []);
	return(
                  <div className="blog-post-card-1-28">
                    <div className="image-1-19">
                      <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${data.medias?.split(",")[0]})` }}>
                        {" "}
                      </div>
                      <div className="cart-1-1242">
                        <div className="icon--shoppingo-1-1882">
                          <div className="vector-1-15">
                            <div className="nodeBg-I19043604_2345684_2345677_351646"></div>
                          </div>
                        </div>
                      </div>
                     {/* <div className="rate-1-320">
                        <div className="text-1-117">
                            <span className="this-is-a-text-1-244">
                              {"16 reviews"}
                            </span>
                        </div>
                        <div className="frame-5-1-4653">
                          {[...Array(4)].map(x => (
                          	<div className="rate-star-1-756">
                              <div className="vector-1-159">
                                <div className="nodeBg-I19043604_18532088_39611242_35172"></div>
                              </div>
                          </div>
                          ))}
                          <div className="rate-star-5-4779">
                            <div className="mask-group-1-105">
                              <div className="nodeBg-I19043604_18532092_39611239">
                                {" "}
                              </div>
                            </div>
                          </div>
                          <div className="text-1-2077">
                            <div className="this-is-a-text-1-810">
                              <span className="this-is-a-text-1-810-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>*/}
                    </div>
                    <div className="content-1-322">
                        <div className="olivia-rhye--20-1-1175">
                          <span className="olivia-rhye--20-1-1175-0">
                            {user.organizationName}&nbsp;{"|"}  &nbsp;
                            {data.price}&nbsp; BR
                          </span>
                        </div>
                        <div className="heading-and-ico-1-663">
                          <div className="heading-1-160">
                            <span className="heading-1-160-0">
                              {data.productName}
                            </span>
                          </div>
                          <div className="icon-wrap-1-582">
                              <div className="icon-1-296">
                                <div className="nodeBg-I19043604_18532101_3463404516"></div>
                              </div>
                            </div>
                        </div>
                        <div className="supporting-text-1-2849">
                          <span className="supporting-text-1-2849-0">
                            {
                              data.description
                            }
                          </span>
                        </div>
                      </div>
                    <div onClick={(e)=>addToCart(data.id)} className="buttonsbutton-1-225">
                          <span className="text-1-1-0">{"Add to Cart"}</span>
                    </div>
                  </div>
                  )
}
export default BlogCard