import React  from "react";
import "./SignUp.css";
import FirstPage from "./components/FirstPage";
// import Blog from "./components/Blog"
const SignUp=()=>{
	return(
    <>
    <FirstPage />
    {/*<Blog />*/}
    </>
		)
}
export default SignUp