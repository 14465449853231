import React  from "react";
import { Link } from "react-router-dom";
import "./style.css";
import "./animation.css";
const ServiceTwo = () => {
  return (
    <div className="service-tow-parent-div">
      <div className="frame-11-1-490" id="id-19043614">
        <div className="text1-0-2800" id="id-14275">
          <span className="text1-0-2800-0">{"Button CTA"}</span>
        </div>
        <div className="placeholder1-0-638" id="id-14283">
          <div className="icon-1-57" id="id-I14283_3465410041">
            <div
              className="nodeBg-I14283_3465410041"
              id="id-bg-I14283_3465410041"
            ></div>
          </div>
        </div>
        <div className="hero-header-sec-1-610" id="id-19043615">
          <div className="background-patt-1-704" id="id-19043617"></div>
          <div className="dropdown-header-1-136" id="id-19043616">
            <div className="header-1-1406" id="id-I19043616_128830712">
              <div className="container-1-2262" id="id-I19043616_128830713">
                <div
                  className="navigation-acti-1-3599"
                  id="id-I19043616_1624307186"
                >
                  <div
                    className="buttonsbutton-1-1647"
                    id="id-I19043616_1624307186_1624262067"
                  >
                    <div
                      className="text-padding-1-564"
                      id="id-I19043616_1624307186_1624262067_6421275185"
                    >
                      <div
                        className="text-1-704"
                        id="id-I19043616_1624307186_1624262067_3287433257"
                      >
                        <span className="text-1-704-0">{"Log in"}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="buttonsbutton-2-320"
                    id="id-I19043616_1624307186_1624262069"
                  >
                    <div
                      className="text-padding-1-2862"
                      id="id-I19043616_1624307186_1624262069_6421273911"
                    >
                      <div
                        className="text-1-429"
                        id="id-I19043616_1624307186_1624262069_3287432949"
                      >
                        <span className="text-1-429-0">
                          {"Get Started Now!"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-1-2784" id="id-I19043616_128830714">
                  <div className="navigation-1-504" id="id-I19043616_128830716">
                    <div
                      className="buttonsbutton-1-64"
                      id="id-I19043616_128830717"
                    >
                    <Link to="/">
                      <div
                        className="text-1-1840"
                        id="id-I19043616_128830717_3287433397"
                      >
                        <span className="text-1-1840-0">{"Home"}</span>
                      </div>
                      </Link>
                    </div>
                    <div
                      className="buttonsbutton-2-456"
                      id="id-I19043616_128830720"
                    >
                      <div
                        className="text-1-0"
                        id="id-I19043616_128830720_3287433397"
                      >
                        <span className="text-1-0-0">{"About us"}</span>
                      </div>
                    </div>
                    <div
                      className="dropdown-header-1-1288"
                      id="id-I19043616_128830718"
                    >
                      <div
                        className="buttonsbutton-1-2055"
                        id="id-I19043616_128830718_1288478"
                      >
                        <div
                          className="text-1-44"
                          id="id-I19043616_128830718_1288478_3287433397"
                        >
                          <span className="text-1-44-0">{"Vendors"}</span>
                        </div>
                        <div
                          className="chevrondown-1-1722"
                          id="id-I19043616_128830718_1288478_3468468154"
                        >
                          <div
                            className="icon-1-1680"
                            id="id-I19043616_128830718_1288478_3468468154_3463404534"
                          >
                            <div
                              className="nodeBg-I19043616_128830718_1288478_3468468154_3463404534"
                              id="id-bg-I19043616_128830718_1288478_3468468154_3463404534"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="dropdown-header-2-1408"
                      id="id-I19043616_128830719"
                    >
                      <div
                        className="buttonsbutton-1-2115"
                        id="id-I19043616_128830719_1288478"
                      >
                        <div
                          className="text-1-190"
                          id="id-I19043616_128830719_1288478_3287433397"
                        >
                          <span className="text-1-190-0">{"Resources"}</span>
                        </div>
                        <div
                          className="chevrondown-1-3320"
                          id="id-I19043616_128830719_1288478_3468468154"
                        >
                          <div
                            className="icon-1-1485"
                            id="id-I19043616_128830719_1288478_3468468154_3463404534"
                          >
                            <div
                              className="nodeBg-I19043616_128830719_1288478_3468468154_3463404534"
                              id="id-bg-I19043616_128830719_1288478_3468468154_3463404534"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tilosh-1-240" id="id-I19043616_128830715">
                    <div
                      className="tilosh-1-580"
                      id="id-I19043616_128830715_1612120"
                    >
                      <div
                        className="layer-1-1-957"
                        id="id-I19043616_128830715_1612120_33235"
                      >
                        <div
                          className="group-1-1584"
                          id="id-I19043616_128830715_1612120_33236"
                        >
                          <div
                            className="group-1-1880"
                            id="id-I19043616_128830715_1612120_33237"
                          >
                            <div
                              className="vector-1-276"
                              id="id-I19043616_128830715_1612120_33238"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33238"
                                id="id-bg-I19043616_128830715_1612120_33238"
                              ></div>
                            </div>
                            <div
                              className="vector-2-1624"
                              id="id-I19043616_128830715_1612120_33239"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33239"
                                id="id-bg-I19043616_128830715_1612120_33239"
                              ></div>
                            </div>
                            <div
                              className="vector-3-4770"
                              id="id-I19043616_128830715_1612120_33240"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33240"
                                id="id-bg-I19043616_128830715_1612120_33240"
                              ></div>
                            </div>
                            <div
                              className="vector-4-4500"
                              id="id-I19043616_128830715_1612120_33241"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33241"
                                id="id-bg-I19043616_128830715_1612120_33241"
                              ></div>
                            </div>
                            <div
                              className="vector-5-4048"
                              id="id-I19043616_128830715_1612120_33242"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33242"
                                id="id-bg-I19043616_128830715_1612120_33242"
                              ></div>
                            </div>
                            <div
                              className="vector-6-2730"
                              id="id-I19043616_128830715_1612120_33243"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33243"
                                id="id-bg-I19043616_128830715_1612120_33243"
                              ></div>
                            </div>
                          </div>
                          <div
                            className="group-2-162"
                            id="id-I19043616_128830715_1612120_33244"
                          >
                            <div
                              className="vector-1-2706"
                              id="id-I19043616_128830715_1612120_33245"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33245"
                                id="id-bg-I19043616_128830715_1612120_33245"
                              ></div>
                            </div>
                            <div
                              className="vector-2-4312"
                              id="id-I19043616_128830715_1612120_33246"
                            >
                              <div
                                className="nodeBg-I19043616_128830715_1612120_33246"
                                id="id-bg-I19043616_128830715_1612120_33246"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header1-0-2442" id="id-1653094">
          <div className="container-1-938" id="id-1653095">
            <div className="navigation-acti-1-2040" id="id-1653103">
              <div className="buttonsbutton-1-576" id="id-I1653103_1624262067">
                <div
                  className="text-padding-1-0"
                  id="id-I1653103_1624262067_6421275185"
                >
                  <div
                    className="text-1-49"
                    id="id-I1653103_1624262067_3287433257"
                  >
                    <span className="text-1-49-0">{"Log in"}</span>
                  </div>
                </div>
              </div>
              <div className="buttonsbutton-2-1804" id="id-I1653103_1624262069">
                <div
                  className="text-padding-1-2232"
                  id="id-I1653103_1624262069_6421273911"
                >
                  <div
                    className="text-1-1053"
                    id="id-I1653103_1624262069_3287432949"
                  >
                    <span className="text-1-1053-0">{"Sign up"}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-1-770" id="id-1653096">
              <div className="logo-1-611" id="id-1653097">
                <div className="logo-wrap-1-2449" id="id-I1653097_2924375962">
                  <div className="logotype-1-44" id="id-I1653097_1532267709">
                    <div className="vector-1-488" id="id-I1653097_3563407518">
                      <div
                        className="nodeBg-I1653097_3563407518"
                        id="id-bg-I1653097_3563407518"
                      ></div>
                    </div>
                  </div>
                  <div className="logomark-1-4400" id="id-I1653097_108350705">
                    <div
                      className="content-1-2160"
                      id="id-I1653097_108350705_110166343"
                    >
                      <div
                        className="dot-1-682"
                        id="id-I1653097_108350705_108350481"
                      ></div>
                      <div
                        className="blur-1-46"
                        id="id-I1653097_108350705_110181660"
                      ></div>
                      <div
                        className="grid-1-2790"
                        id="id-I1653097_108350705_108189"
                      >
                        <div
                          className="circle-1-320"
                          id="id-I1653097_108350705_110244"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110244"
                            id="id-bg-I1653097_108350705_110244"
                          ></div>
                        </div>
                        <div
                          className="circle-2-572"
                          id="id-I1653097_108350705_110245"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110245"
                            id="id-bg-I1653097_108350705_110245"
                          ></div>
                        </div>
                        <div
                          className="circle-3-328"
                          id="id-I1653097_108350705_110246"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110246"
                            id="id-bg-I1653097_108350705_110246"
                          ></div>
                        </div>
                        <div
                          className="line-1-600"
                          id="id-I1653097_108350705_110247"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110247"
                            id="id-bg-I1653097_108350705_110247"
                          ></div>
                        </div>
                        <div
                          className="line-2-2574"
                          id="id-I1653097_108350705_110248"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110248"
                            id="id-bg-I1653097_108350705_110248"
                          ></div>
                        </div>
                        <div
                          className="line-3-1792"
                          id="id-I1653097_108350705_110249"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110249"
                            id="id-bg-I1653097_108350705_110249"
                          ></div>
                        </div>
                        <div
                          className="line-4-174"
                          id="id-I1653097_108350705_110250"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110250"
                            id="id-bg-I1653097_108350705_110250"
                          ></div>
                        </div>
                        <div
                          className="line-5-1800"
                          id="id-I1653097_108350705_110251"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110251"
                            id="id-bg-I1653097_108350705_110251"
                          ></div>
                        </div>
                        <div
                          className="line-6-341"
                          id="id-I1653097_108350705_110252"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110252"
                            id="id-bg-I1653097_108350705_110252"
                          ></div>
                        </div>
                        <div
                          className="line-7-12"
                          id="id-I1653097_108350705_110253"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110253"
                            id="id-bg-I1653097_108350705_110253"
                          ></div>
                        </div>
                        <div
                          className="line-8-518"
                          id="id-I1653097_108350705_110254"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110254"
                            id="id-bg-I1653097_108350705_110254"
                          ></div>
                        </div>
                        <div
                          className="line-9-0"
                          id="id-I1653097_108350705_110255"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110255"
                            id="id-bg-I1653097_108350705_110255"
                          ></div>
                        </div>
                        <div
                          className="line-10-570"
                          id="id-I1653097_108350705_110256"
                        >
                          <div
                            className="nodeBg-I1653097_108350705_110256"
                            id="id-bg-I1653097_108350705_110256"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="navigation-1-1656" id="id-1653098">
                <div className="buttonsbutton-1-1395" id="id-1653099">
                  <div className="text-1-3344" id="id-I1653099_3287433397">
                    <span className="text-1-3344-0">{"Home"}</span>
                  </div>
                </div>
                <div className="buttonsbutton-2-1344" id="id-1653102">
                  <div className="text-1-3172" id="id-I1653102_3287433397">
                    <span className="text-1-3172-0">{"Pricing"}</span>
                  </div>
                </div>
                <div className="dropdown-header-1-477" id="id-1653100">
                  <div
                    className="buttonsbutton-1-3698"
                    id="id-I1653100_1288478"
                  >
                    <div
                      className="text-1-3172"
                      id="id-I1653100_1288478_3287433397"
                    >
                      <span className="text-1-3172-0">{"Products"}</span>
                    </div>
                    <div
                      className="chevrondown-1-3762"
                      id="id-I1653100_1288478_3468468154"
                    >
                      <div
                        className="icon-1-3034"
                        id="id-I1653100_1288478_3468468154_3463404534"
                      >
                        <div
                          className="nodeBg-I1653100_1288478_3468468154_3463404534"
                          id="id-bg-I1653100_1288478_3468468154_3463404534"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown-header-2-0" id="id-1653101">
                  <div className="buttonsbutton-1-145" id="id-I1653101_1288474">
                    <div
                      className="text-1-2208"
                      id="id-I1653101_1288474_3287433397"
                    >
                      <span className="text-1-2208-0">{"Resources"}</span>
                    </div>
                    <div
                      className="chevronup-1-1111"
                      id="id-I1653101_1288474_3468468154"
                    >
                      <div
                        className="icon-1-3213"
                        id="id-I1653101_1288474_3468468154_3463404558"
                      >
                        <div
                          className="nodeBg-I1653101_1288474_3468468154_3463404558"
                          id="id-bg-I1653101_1288474_3468468154_3463404558"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="menu-1-810" id="id-I1653101_4942447029">
                    <div className="content-1-210" id="id-I1653101_4942447030">
                      <div
                        className="nav-menu-item-1-119"
                        id="id-I1653101_4942447031"
                      >
                        <div
                          className="content-1-747"
                          id="id-I1653101_4942447031_128214433"
                        >
                          <div
                            className="text-and-suppor-1-4900"
                            id="id-I1653101_4942447031_128214434"
                          >
                            <div
                              className="the-latest-indu-1-3116"
                              id="id-I1653101_4942447031_128214438"
                            >
                              <span className="the-latest-indu-1-3116-0">
                                {"The latest industry news, updates and info."}
                              </span>
                            </div>
                            <div
                              className="text-and-badge-1-114"
                              id="id-I1653101_4942447031_128214435"
                            >
                              <div
                                className="text-1-936"
                                id="id-I1653101_4942447031_128214436"
                              >
                                <span className="text-1-936-0">{"Blog"}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="bookclosed-1-90"
                          id="id-I1653101_4942447031_3474462889"
                        >
                          <div
                            className="icon-1-4418"
                            id="id-I1653101_4942447031_3474462889_3463404148"
                          >
                            <div
                              className="nodeBg-I1653101_4942447031_3474462889_3463404148"
                              id="id-bg-I1653101_4942447031_3474462889_3463404148"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="nav-menu-item-2-392"
                        id="id-I1653101_4942447032"
                      >
                        <div
                          className="content-1-1408"
                          id="id-I1653101_4942447032_128214433"
                        >
                          <div
                            className="text-and-suppor-1-2021"
                            id="id-I1653101_4942447032_128214434"
                          >
                            <div
                              className="supporting-text-1-2464"
                              id="id-I1653101_4942447032_128214438"
                            >
                              <span className="supporting-text-1-2464-0">
                                {
                                  "Learn how our customers are making big changes."
                                }
                              </span>
                            </div>
                            <div
                              className="text-and-badge-1-1875"
                              id="id-I1653101_4942447032_128214435"
                            >
                              <div
                                className="text-1-4071"
                                id="id-I1653101_4942447032_128214436"
                              >
                                <span className="text-1-4071-0">
                                  {"Customer stories"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="stars02-1-2279"
                          id="id-I1653101_4942447032_3474462889"
                        >
                          <div
                            className="icon-1-4539"
                            id="id-I1653101_4942447032_3474462889_3463407150"
                          >
                            <div
                              className="nodeBg-I1653101_4942447032_3474462889_3463407150"
                              id="id-bg-I1653101_4942447032_3474462889_3463407150"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="nav-menu-item-3-200"
                        id="id-I1653101_4942447033"
                      >
                        <div
                          className="content-1-1107"
                          id="id-I1653101_4942447033_128214433"
                        >
                          <div
                            className="text-and-suppor-1-2574"
                            id="id-I1653101_4942447033_128214434"
                          >
                            <div
                              className="get-up-and-runn-1-198"
                              id="id-I1653101_4942447033_128214438"
                            >
                              <span className="get-up-and-runn-1-198-0">
                                {
                                  "Get up and running on new features and techniques."
                                }
                              </span>
                            </div>
                            <div
                              className="text-and-badge-1-2765"
                              id="id-I1653101_4942447033_128214435"
                            >
                              <div
                                className="text-1-625"
                                id="id-I1653101_4942447033_128214436"
                              >
                                <span className="text-1-625-0">
                                  {"Video tutorials"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="playcircle-1-3150"
                          id="id-I1653101_4942447033_3474462889"
                        >
                          <div
                            className="icon-1-840"
                            id="id-I1653101_4942447033_3474462889_3463405927"
                          >
                            <div
                              className="nodeBg-I1653101_4942447033_3474462889_3463405927"
                              id="id-bg-I1653101_4942447033_3474462889_3463405927"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-2-1520" id="id-I1653101_4942447034">
                      <div
                        className="nav-menu-item-c-1-1318"
                        id="id-I1653101_4942447035"
                      >
                        <div
                          className="content-1-2065"
                          id="id-I1653101_4942447035_128219897"
                        >
                          <div
                            className="text-and-suppor-1-108"
                            id="id-I1653101_4942447035_128219898"
                          >
                            <div
                              className="text-1-456"
                              id="id-I1653101_4942447035_128219899"
                            >
                              <span className="text-1-456-0">
                                {"We've just released an update!"}
                              </span>
                            </div>
                            <div
                              className="supporting-text-1-943"
                              id="id-I1653101_4942447035_128219900"
                            >
                              <span className="supporting-text-1-943-0">
                                {
                                  "Check out the all new dashboard view. Pages now load faster. "
                                }
                              </span>
                            </div>
                          </div>
                          <div
                            className="actions-1-1449"
                            id="id-I1653101_4942447035_128219901"
                          >
                            <div
                              className="buttonsbutton-1-3294"
                              id="id-I1653101_4942447035_128219902"
                            >
                              <div
                                className="text-1-97"
                                id="id-I1653101_4942447035_128219902_3287433377"
                              >
                                <span className="text-1-97-0">{"Dismiss"}</span>
                              </div>
                            </div>
                            <div
                              className="buttonsbutton-2-1911"
                              id="id-I1653101_4942447035_128219903"
                            >
                              <div
                                className="text-1-0"
                                id="id-I1653101_4942447035_128219903_3287433289"
                              >
                                <span className="text-1-0-0">
                                  {"Changelog"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="image-wrap-1-146"
                          id="id-I1653101_4942447035_128219895"
                        >
                          <div
                            className="image-1-2755"
                            id="id-I1653101_4942447035_128219896"
                          >
                            <div
                              className="nodeBg-I1653101_4942447035_128219896"
                              id="id-bg-I1653101_4942447035_128219896"
                            >
                              {" "}
                            </div>
                          </div>
                          <div
                            className="play-button-1-384"
                            id="id-I1653101_4942447035_2647402504"
                          >
                            <div
                              className="button-1-2970"
                              id="id-I1653101_4942447035_2647402504_137712743"
                            >
                              <div
                                className="nodeBg-I1653101_4942447035_2647402504_137712743"
                                id="id-bg-I1653101_4942447035_2647402504_137712743"
                              >
                                {" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-padding1-0-12" id="id-14284">
          <div className="text-1-2480" id="id-14285">
            <span className="text-1-2480-0">{"Button CTA"}</span>
          </div>
        </div>
        <div className="menu1-0-184" id="id-16785">
          <div className="content-1-91" id="id-16786">
            <div className="nav-menu-item-1-865" id="id-16787">
              <div className="content-1-288" id="id-I16787_128214433">
                <div className="text-and-suppor-1-385" id="id-I16787_128214434">
                  <div
                    className="the-latest-indu-1-3834"
                    id="id-I16787_128214438"
                  >
                    <span className="the-latest-indu-1-3834-0">
                      {"The latest industry news, updates and info."}
                    </span>
                  </div>
                  <div className="text-and-badge-1-68" id="id-I16787_128214435">
                    <div className="text-1-744" id="id-I16787_128214436">
                      <span className="text-1-744-0">{"Blog"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookclosed-1-462" id="id-I16787_3474462889">
                <div
                  className="icon-1-2460"
                  id="id-I16787_3474462889_3463404148"
                >
                  <div
                    className="nodeBg-I16787_3474462889_3463404148"
                    id="id-bg-I16787_3474462889_3463404148"
                  ></div>
                </div>
              </div>
            </div>
            <div className="nav-menu-item-2-1768" id="id-16788">
              <div className="content-1-664" id="id-I16788_128214433">
                <div
                  className="text-and-suppor-1-1568"
                  id="id-I16788_128214434"
                >
                  <div
                    className="supporting-text-1-4898"
                    id="id-I16788_128214438"
                  >
                    <span className="supporting-text-1-4898-0">
                      {"Learn how our customers are making big changes."}
                    </span>
                  </div>
                  <div
                    className="text-and-badge-1-3160"
                    id="id-I16788_128214435"
                  >
                    <div className="text-1-808" id="id-I16788_128214436">
                      <span className="text-1-808-0">{"Customer stories"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stars02-1-2961" id="id-I16788_3474462889">
                <div
                  className="icon-1-251"
                  id="id-I16788_3474462889_3463407150"
                >
                  <div
                    className="nodeBg-I16788_3474462889_3463407150"
                    id="id-bg-I16788_3474462889_3463407150"
                  ></div>
                </div>
              </div>
            </div>
            <div className="nav-menu-item-3-2627" id="id-16789">
              <div className="content-1-865" id="id-I16789_128214433">
                <div
                  className="text-and-suppor-1-2470"
                  id="id-I16789_128214434"
                >
                  <div
                    className="get-up-and-runn-1-848"
                    id="id-I16789_128214438"
                  >
                    <span className="get-up-and-runn-1-848-0">
                      {"Get up and running on new features and techniques."}
                    </span>
                  </div>
                  <div
                    className="text-and-badge-1-551"
                    id="id-I16789_128214435"
                  >
                    <div className="text-1-2829" id="id-I16789_128214436">
                      <span className="text-1-2829-0">{"Video tutorials"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="playcircle-1-435" id="id-I16789_3474462889">
                <div
                  className="icon-1-305"
                  id="id-I16789_3474462889_3463405927"
                >
                  <div
                    className="nodeBg-I16789_3474462889_3463405927"
                    id="id-bg-I16789_3474462889_3463405927"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-2-2542" id="id-16790">
            <div className="nav-menu-item-c-1-54" id="id-16791">
              <div className="content-1-3520" id="id-I16791_128219897">
                <div
                  className="text-and-suppor-1-2560"
                  id="id-I16791_128219898"
                >
                  <div className="text-1-4100" id="id-I16791_128219899">
                    <span className="text-1-4100-0">
                      {"We've just released an update!"}
                    </span>
                  </div>
                  <div
                    className="supporting-text-1-159"
                    id="id-I16791_128219900"
                  >
                    <span className="supporting-text-1-159-0">
                      {
                        "Check out the all new dashboard view. Pages now load faster. "
                      }
                    </span>
                  </div>
                </div>
                <div className="actions-1-3645" id="id-I16791_128219901">
                  <div className="buttonsbutton-1-442" id="id-I16791_128219902">
                    <div
                      className="text-1-3415"
                      id="id-I16791_128219902_3287433377"
                    >
                      <span className="text-1-3415-0">{"Dismiss"}</span>
                    </div>
                  </div>
                  <div className="buttonsbutton-2-416" id="id-I16791_128219903">
                    <div
                      className="text-1-4950"
                      id="id-I16791_128219903_3287433289"
                    >
                      <span className="text-1-4950-0">{"Changelog"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="image-wrap-1-34" id="id-I16791_128219895">
                <div className="image-1-2720" id="id-I16791_128219896">
                  <div
                    className="nodeBg-I16791_128219896"
                    id="id-bg-I16791_128219896"
                  >
                    {" "}
                  </div>
                </div>
                <div className="play-button-1-240" id="id-I16791_2647402504">
                  <div
                    className="button-1-1157"
                    id="id-I16791_2647402504_137712743"
                  >
                    <div
                      className="nodeBg-I16791_2647402504_137712743"
                      id="id-bg-I16791_2647402504_137712743"
                    >
                      {" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-with-labe-0-1873" id="id-112871">
          <div className="label-1-396" id="id-112872">
            <span className="label-1-396-0">{"Sale amount"}</span>
          </div>
          <div className="input-1-1566" id="id-112873">
            <div className="dropdown-1-1975" id="id-112879">
              <div className="dropdown-text-1-2812" id="id-112880">
                <span className="dropdown-text-1-2812-0">{"USD"}</span>
              </div>
              <div className="chevrondown-1-290" id="id-112881">
                <div className="icon-1-3960" id="id-I112881_3463404534">
                  <div
                    className="nodeBg-I112881_3463404534"
                    id="id-bg-I112881_3463404534"
                  ></div>
                </div>
              </div>
            </div>
            <div className="content-1-2240" id="id-112874">
              <div className="text-input-1-272" id="id-112875">
                <div className="leading-text-1-2250" id="id-112876">
                  <span className="leading-text-1-2250-0">{"$"}</span>
                </div>
                <div className="text-1-2548" id="id-112877">
                  <span className="text-1-2548-0">{"1,000.00"}</span>
                </div>
              </div>
              <div className="help-icon-1-858" id="id-112878">
                <div className="helpcircle-1-2310" id="id-I112878_105467">
                  <div className="icon-1-472" id="id-I112878_105467_3463405412">
                    <div
                      className="nodeBg-I112878_105467_3463405412"
                      id="id-bg-I112878_105467_3463405412"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="placeholder2-0-4030" id="id-14286">
          <div className="icon-1-366" id="id-I14286_3465410041">
            <div
              className="nodeBg-I14286_3465410041"
              id="id-bg-I14286_3465410041"
            ></div>
          </div>
        </div>
        <div className="buttonsbutton1-0-2135" id="id-16784">
          <div className="text-1-1138" id="id-I16784_3287433397">
            <span className="text-1-1138-0">{"Resources"}</span>
          </div>
          <div className="chevronup-1-1720" id="id-I16784_3468468154">
            <div className="icon-1-845" id="id-I16784_3468468154_3463404558">
              <div
                className="nodeBg-I16784_3468468154_3463404558"
                id="id-bg-I16784_3468468154_3463404558"
              ></div>
            </div>
          </div>
        </div>
        <div className="hint-text1-0-3064" id="id-112882">
          <span className="hint-text1-0-3064-0">
            {"This is a hint text to help user."}
          </span>
        </div>
        <div className="blog-section-1-1314" id="id-19043618">
          <div className="blog-section-1-2166" id="id-19043619">
            <div className="rectangle-3-1-792" id="id-19043620">
              <div className="nodeBg-19043620" id="id-bg-19043620">
                {" "}
              </div>
            </div>
            <div className="frame-16-1-4160" id="id-19043646">
              <div className="promotion-1-901" id="id-19043647">
                <span className="promotion-1-901-0">{"Promotion"}</span>
              </div>
            </div>
            <div className="frame-15-1-1720" id="id-19043621">
              <div className="container-1-1846" id="id-19043636">
                <div className="content-1-3600" id="id-19043637">
                  <div className="blog-post-card-1-4655" id="id-19043638">
                    <div
                      className="buttonsbutton-1-3185"
                      id="id-I19043638_18532147"
                    >
                      <div
                        className="text-padding-1-750"
                        id="id-I19043638_18532147_6421273911"
                      >
                        <div
                          className="text-1-616"
                          id="id-I19043638_18532147_3287432949"
                        >
                          <span className="text-1-616-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-4136" id="id-I19043638_18532085">
                      <div
                        className="nodeBg-I19043638_18532085"
                        id="id-bg-I19043638_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-2115" id="id-I19043638_2345684">
                        <div
                          className="icon--shoppingo-1-2812"
                          id="id-I19043638_2345684_2345677"
                        >
                          <div
                            className="vector-1-366"
                            id="id-I19043638_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043638_2345684_2345677_351646"
                              id="id-bg-I19043638_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-2736" id="id-I19043638_18532086">
                        <div className="text-1-944" id="id-I19043638_18532094">
                          <div
                            className="this-is-a-text-1-3649"
                            id="id-I19043638_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-3649-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-3526"
                          id="id-I19043638_18532087"
                        >
                          <div
                            className="text-1-1540"
                            id="id-I19043638_18532093"
                          >
                            <div
                              className="this-is-a-text-1-390"
                              id="id-I19043638_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-390-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-497"
                            id="id-I19043638_18532088"
                          >
                            <div
                              className="icon--starfille-1-1927"
                              id="id-I19043638_18532088_39611242"
                            >
                              <div
                                className="vector-1-1500"
                                id="id-I19043638_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043638_18532088_39611242_35172"
                                  id="id-bg-I19043638_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-2044"
                            id="id-I19043638_18532089"
                          >
                            <div
                              className="icon--starfille-1-1440"
                              id="id-I19043638_18532089_39611242"
                            >
                              <div
                                className="vector-1-1690"
                                id="id-I19043638_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043638_18532089_39611242_35172"
                                  id="id-bg-I19043638_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-429"
                            id="id-I19043638_18532090"
                          >
                            <div
                              className="icon--starfille-1-2844"
                              id="id-I19043638_18532090_39611242"
                            >
                              <div
                                className="vector-1-1936"
                                id="id-I19043638_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043638_18532090_39611242_35172"
                                  id="id-bg-I19043638_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-1603"
                            id="id-I19043638_18532091"
                          >
                            <div
                              className="icon--starfille-1-3534"
                              id="id-I19043638_18532091_39611242"
                            >
                              <div
                                className="vector-1-536"
                                id="id-I19043638_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043638_18532091_39611242_35172"
                                  id="id-bg-I19043638_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-1750"
                            id="id-I19043638_18532092"
                          >
                            <div
                              className="mask-group-1-3128"
                              id="id-I19043638_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043638_18532092_39611239"
                                id="id-bg-I19043638_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-1848"
                              id="id-I19043638_18532092_39611244"
                            >
                              <div
                                className="vector-1-2880"
                                id="id-I19043638_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043638_18532092_39611244_35172"
                                  id="id-bg-I19043638_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-504" id="id-I19043638_18532095">
                      <div
                        className="heading-and-tex-1-696"
                        id="id-I19043638_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-63"
                          id="id-I19043638_18532097"
                        >
                          <span className="olivia-rhye--20-1-63-0">
                            {"Abela velo "}&nbsp;{""} <br /> &nbsp;
                            {"Rent Br. 100k / day"}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-336"
                          id="id-I19043638_18532102"
                        >
                          <span className="supporting-text-1-336-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-120"
                          id="id-I19043638_18532098"
                        >
                          <div
                            className="heading-1-474"
                            id="id-I19043638_18532099"
                          >
                            <span className="heading-1-474-0">{"Vello"}</span>
                          </div>
                          <div
                            className="icon-wrap-1-972"
                            id="id-I19043638_18532100"
                          >
                            <div
                              className="arrowupright-1-3976"
                              id="id-I19043638_18532101"
                            >
                              <div
                                className="icon-1-38"
                                id="id-I19043638_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043638_18532101_3463404516"
                                  id="id-bg-I19043638_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-2-2484" id="id-19043639">
                    <div
                      className="buttonsbutton-1-208"
                      id="id-I19043639_18532147"
                    >
                      <div
                        className="text-padding-1-24"
                        id="id-I19043639_18532147_6421273911"
                      >
                        <div
                          className="text-1-162"
                          id="id-I19043639_18532147_3287432949"
                        >
                          <span className="text-1-162-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-3600" id="id-I19043639_18532085">
                      <div
                        className="nodeBg-I19043639_18532085"
                        id="id-bg-I19043639_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-3577" id="id-I19043639_2345684">
                        <div
                          className="icon--shoppingo-1-1080"
                          id="id-I19043639_2345684_2345677"
                        >
                          <div
                            className="vector-1-966"
                            id="id-I19043639_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043639_2345684_2345677_351646"
                              id="id-bg-I19043639_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-4095" id="id-I19043639_18532086">
                        <div className="text-1-3293" id="id-I19043639_18532094">
                          <div
                            className="this-is-a-text-1-1496"
                            id="id-I19043639_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-1496-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-2652"
                          id="id-I19043639_18532087"
                        >
                          <div
                            className="text-1-3200"
                            id="id-I19043639_18532093"
                          >
                            <div
                              className="this-is-a-text-1-1653"
                              id="id-I19043639_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-1653-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-4214"
                            id="id-I19043639_18532088"
                          >
                            <div
                              className="icon--starfille-1-40"
                              id="id-I19043639_18532088_39611242"
                            >
                              <div
                                className="vector-1-546"
                                id="id-I19043639_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043639_18532088_39611242_35172"
                                  id="id-bg-I19043639_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-1349"
                            id="id-I19043639_18532089"
                          >
                            <div
                              className="icon--starfille-1-273"
                              id="id-I19043639_18532089_39611242"
                            >
                              <div
                                className="vector-1-4655"
                                id="id-I19043639_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043639_18532089_39611242_35172"
                                  id="id-bg-I19043639_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-2548"
                            id="id-I19043639_18532090"
                          >
                            <div
                              className="icon--starfille-1-1749"
                              id="id-I19043639_18532090_39611242"
                            >
                              <div
                                className="vector-1-2590"
                                id="id-I19043639_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043639_18532090_39611242_35172"
                                  id="id-bg-I19043639_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-2385"
                            id="id-I19043639_18532091"
                          >
                            <div
                              className="icon--starfille-1-533"
                              id="id-I19043639_18532091_39611242"
                            >
                              <div
                                className="vector-1-658"
                                id="id-I19043639_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043639_18532091_39611242_35172"
                                  id="id-bg-I19043639_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-1419"
                            id="id-I19043639_18532092"
                          >
                            <div
                              className="mask-group-1-930"
                              id="id-I19043639_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043639_18532092_39611239"
                                id="id-bg-I19043639_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-2410"
                              id="id-I19043639_18532092_39611244"
                            >
                              <div
                                className="vector-1-1536"
                                id="id-I19043639_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043639_18532092_39611244_35172"
                                  id="id-bg-I19043639_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-1090" id="id-I19043639_18532095">
                      <div
                        className="heading-and-tex-1-0"
                        id="id-I19043639_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-2954"
                          id="id-I19043639_18532097"
                        >
                          <span className="olivia-rhye--20-1-2954-0">
                            {"Abela velo "}&nbsp;{""} <br /> &nbsp;
                            {"Rent Br. 100k / day"}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-900"
                          id="id-I19043639_18532102"
                        >
                          <span className="supporting-text-1-900-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-3780"
                          id="id-I19043639_18532098"
                        >
                          <div
                            className="heading-1-158"
                            id="id-I19043639_18532099"
                          >
                            <span className="heading-1-158-0">{"Suit"}</span>
                          </div>
                          <div
                            className="icon-wrap-1-720"
                            id="id-I19043639_18532100"
                          >
                            <div
                              className="arrowupright-1-86"
                              id="id-I19043639_18532101"
                            >
                              <div
                                className="icon-1-3280"
                                id="id-I19043639_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043639_18532101_3463404516"
                                  id="id-bg-I19043639_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-3-234" id="id-19043640">
                    <div
                      className="buttonsbutton-1-2812"
                      id="id-I19043640_18532147"
                    >
                      <div
                        className="text-padding-1-418"
                        id="id-I19043640_18532147_6421273911"
                      >
                        <div
                          className="text-1-2349"
                          id="id-I19043640_18532147_3287432949"
                        >
                          <span className="text-1-2349-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-3825" id="id-I19043640_18532085">
                      <div
                        className="nodeBg-I19043640_18532085"
                        id="id-bg-I19043640_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-330" id="id-I19043640_2345684">
                        <div
                          className="icon--shoppingo-1-1142"
                          id="id-I19043640_2345684_2345677"
                        >
                          <div
                            className="vector-1-846"
                            id="id-I19043640_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043640_2345684_2345677_351646"
                              id="id-bg-I19043640_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-4165" id="id-I19043640_18532086">
                        <div className="text-1-1679" id="id-I19043640_18532094">
                          <div
                            className="this-is-a-text-1-1656"
                            id="id-I19043640_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-1656-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-1003"
                          id="id-I19043640_18532087"
                        >
                          <div
                            className="text-1-3645"
                            id="id-I19043640_18532093"
                          >
                            <div
                              className="this-is-a-text-1-4686"
                              id="id-I19043640_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-4686-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-950"
                            id="id-I19043640_18532088"
                          >
                            <div
                              className="icon--starfille-1-160"
                              id="id-I19043640_18532088_39611242"
                            >
                              <div
                                className="vector-1-231"
                                id="id-I19043640_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043640_18532088_39611242_35172"
                                  id="id-bg-I19043640_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-3395"
                            id="id-I19043640_18532089"
                          >
                            <div
                              className="icon--starfille-1-382"
                              id="id-I19043640_18532089_39611242"
                            >
                              <div
                                className="vector-1-690"
                                id="id-I19043640_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043640_18532089_39611242_35172"
                                  id="id-bg-I19043640_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-0"
                            id="id-I19043640_18532090"
                          >
                            <div
                              className="icon--starfille-1-2592"
                              id="id-I19043640_18532090_39611242"
                            >
                              <div
                                className="vector-1-190"
                                id="id-I19043640_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043640_18532090_39611242_35172"
                                  id="id-bg-I19043640_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-3960"
                            id="id-I19043640_18532091"
                          >
                            <div
                              className="icon--starfille-1-1862"
                              id="id-I19043640_18532091_39611242"
                            >
                              <div
                                className="vector-1-361"
                                id="id-I19043640_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043640_18532091_39611242_35172"
                                  id="id-bg-I19043640_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-1518"
                            id="id-I19043640_18532092"
                          >
                            <div
                              className="mask-group-1-3182"
                              id="id-I19043640_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043640_18532092_39611239"
                                id="id-bg-I19043640_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-2436"
                              id="id-I19043640_18532092_39611244"
                            >
                              <div
                                className="vector-1-4070"
                                id="id-I19043640_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043640_18532092_39611244_35172"
                                  id="id-bg-I19043640_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-4405" id="id-I19043640_18532095">
                      <div
                        className="heading-and-tex-1-1350"
                        id="id-I19043640_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-880"
                          id="id-I19043640_18532097"
                        >
                          <span className="olivia-rhye--20-1-880-0">
                            {"Abebech Catering "} <br /> &nbsp;{"$.??"}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-616"
                          id="id-I19043640_18532102"
                        >
                          <span className="supporting-text-1-616-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-1683"
                          id="id-I19043640_18532098"
                        >
                          <div
                            className="heading-1-2914"
                            id="id-I19043640_18532099"
                          >
                            <span className="heading-1-2914-0">
                              {"Catering"}
                            </span>
                          </div>
                          <div
                            className="icon-wrap-1-172"
                            id="id-I19043640_18532100"
                          >
                            <div
                              className="arrowupright-1-70"
                              id="id-I19043640_18532101"
                            >
                              <div
                                className="icon-1-3192"
                                id="id-I19043640_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043640_18532101_3463404516"
                                  id="id-bg-I19043640_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-2-414" id="id-19043641">
                <div className="content-1-3124" id="id-19043642">
                  <div className="blog-post-card-1-980" id="id-19043643">
                    <div
                      className="buttonsbutton-1-1680"
                      id="id-I19043643_18532147"
                    >
                      <div
                        className="text-padding-1-1200"
                        id="id-I19043643_18532147_6421273911"
                      >
                        <div
                          className="text-1-2328"
                          id="id-I19043643_18532147_3287432949"
                        >
                          <span className="text-1-2328-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-558" id="id-I19043643_18532085">
                      <div
                        className="nodeBg-I19043643_18532085"
                        id="id-bg-I19043643_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-3330" id="id-I19043643_2345684">
                        <div
                          className="icon--shoppingo-1-3019"
                          id="id-I19043643_2345684_2345677"
                        >
                          <div
                            className="vector-1-1104"
                            id="id-I19043643_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043643_2345684_2345677_351646"
                              id="id-bg-I19043643_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-396" id="id-I19043643_18532086">
                        <div className="text-1-1300" id="id-I19043643_18532094">
                          <div
                            className="this-is-a-text-1-1462"
                            id="id-I19043643_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-1462-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-240"
                          id="id-I19043643_18532087"
                        >
                          <div
                            className="text-1-1044"
                            id="id-I19043643_18532093"
                          >
                            <div
                              className="this-is-a-text-1-4240"
                              id="id-I19043643_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-4240-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-2898"
                            id="id-I19043643_18532088"
                          >
                            <div
                              className="icon--starfille-1-552"
                              id="id-I19043643_18532088_39611242"
                            >
                              <div
                                className="vector-1-3318"
                                id="id-I19043643_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043643_18532088_39611242_35172"
                                  id="id-bg-I19043643_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-1189"
                            id="id-I19043643_18532089"
                          >
                            <div
                              className="icon--starfille-1-174"
                              id="id-I19043643_18532089_39611242"
                            >
                              <div
                                className="vector-1-3237"
                                id="id-I19043643_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043643_18532089_39611242_35172"
                                  id="id-bg-I19043643_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-549"
                            id="id-I19043643_18532090"
                          >
                            <div
                              className="icon--starfille-1-1500"
                              id="id-I19043643_18532090_39611242"
                            >
                              <div
                                className="vector-1-1208"
                                id="id-I19043643_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043643_18532090_39611242_35172"
                                  id="id-bg-I19043643_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-3068"
                            id="id-I19043643_18532091"
                          >
                            <div
                              className="icon--starfille-1-2806"
                              id="id-I19043643_18532091_39611242"
                            >
                              <div
                                className="vector-1-1850"
                                id="id-I19043643_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043643_18532091_39611242_35172"
                                  id="id-bg-I19043643_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-0"
                            id="id-I19043643_18532092"
                          >
                            <div
                              className="mask-group-1-332"
                              id="id-I19043643_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043643_18532092_39611239"
                                id="id-bg-I19043643_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-2772"
                              id="id-I19043643_18532092_39611244"
                            >
                              <div
                                className="vector-1-1650"
                                id="id-I19043643_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043643_18532092_39611244_35172"
                                  id="id-bg-I19043643_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-1917" id="id-I19043643_18532095">
                      <div
                        className="heading-and-tex-1-100"
                        id="id-I19043643_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-285"
                          id="id-I19043643_18532097"
                        >
                          <span className="olivia-rhye--20-1-285-0">
                            {"Chala photo and Video "}&nbsp;{""} <br /> &nbsp;
                            {"Br. 100k "}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-3060"
                          id="id-I19043643_18532102"
                        >
                          <span className="supporting-text-1-3060-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-775"
                          id="id-I19043643_18532098"
                        >
                          <div
                            className="heading-1-1384"
                            id="id-I19043643_18532099"
                          >
                            <span className="heading-1-1384-0">
                              {"Photography&Videography"}
                            </span>
                          </div>
                          <div
                            className="icon-wrap-1-2015"
                            id="id-I19043643_18532100"
                          >
                            <div
                              className="arrowupright-1-2553"
                              id="id-I19043643_18532101"
                            >
                              <div
                                className="icon-1-3724"
                                id="id-I19043643_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043643_18532101_3463404516"
                                  id="id-bg-I19043643_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-2-3217" id="id-19043644">
                    <div
                      className="buttonsbutton-1-192"
                      id="id-I19043644_18532147"
                    >
                      <div
                        className="text-padding-1-616"
                        id="id-I19043644_18532147_6421273911"
                      >
                        <div
                          className="text-1-1898"
                          id="id-I19043644_18532147_3287432949"
                        >
                          <span className="text-1-1898-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-2464" id="id-I19043644_18532085">
                      <div
                        className="nodeBg-I19043644_18532085"
                        id="id-bg-I19043644_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-249" id="id-I19043644_2345684">
                        <div
                          className="icon--shoppingo-1-2214"
                          id="id-I19043644_2345684_2345677"
                        >
                          <div
                            className="vector-1-720"
                            id="id-I19043644_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043644_2345684_2345677_351646"
                              id="id-bg-I19043644_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-2352" id="id-I19043644_18532086">
                        <div className="text-1-1539" id="id-I19043644_18532094">
                          <div
                            className="this-is-a-text-1-988"
                            id="id-I19043644_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-988-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-1649"
                          id="id-I19043644_18532087"
                        >
                          <div
                            className="text-1-1320"
                            id="id-I19043644_18532093"
                          >
                            <div
                              className="this-is-a-text-1-1802"
                              id="id-I19043644_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-1802-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-3128"
                            id="id-I19043644_18532088"
                          >
                            <div
                              className="icon--starfille-1-2232"
                              id="id-I19043644_18532088_39611242"
                            >
                              <div
                                className="vector-1-73"
                                id="id-I19043644_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043644_18532088_39611242_35172"
                                  id="id-bg-I19043644_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-2070"
                            id="id-I19043644_18532089"
                          >
                            <div
                              className="icon--starfille-1-840"
                              id="id-I19043644_18532089_39611242"
                            >
                              <div
                                className="vector-1-1696"
                                id="id-I19043644_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043644_18532089_39611242_35172"
                                  id="id-bg-I19043644_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-1680"
                            id="id-I19043644_18532090"
                          >
                            <div
                              className="icon--starfille-1-0"
                              id="id-I19043644_18532090_39611242"
                            >
                              <div
                                className="vector-1-3526"
                                id="id-I19043644_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043644_18532090_39611242_35172"
                                  id="id-bg-I19043644_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-1419"
                            id="id-I19043644_18532091"
                          >
                            <div
                              className="icon--starfille-1-254"
                              id="id-I19043644_18532091_39611242"
                            >
                              <div
                                className="vector-1-3465"
                                id="id-I19043644_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043644_18532091_39611242_35172"
                                  id="id-bg-I19043644_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-3136"
                            id="id-I19043644_18532092"
                          >
                            <div
                              className="mask-group-1-385"
                              id="id-I19043644_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043644_18532092_39611239"
                                id="id-bg-I19043644_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-1920"
                              id="id-I19043644_18532092_39611244"
                            >
                              <div
                                className="vector-1-1209"
                                id="id-I19043644_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043644_18532092_39611244_35172"
                                  id="id-bg-I19043644_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-70" id="id-I19043644_18532095">
                      <div
                        className="heading-and-tex-1-950"
                        id="id-I19043644_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-1360"
                          id="id-I19043644_18532097"
                        >
                          <span className="olivia-rhye--20-1-1360-0">
                            {"Beti Beauty Salon "}&nbsp;{""} <br /> &nbsp;
                            {"Br. 15k"}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-138"
                          id="id-I19043644_18532102"
                        >
                          <span className="supporting-text-1-138-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-60"
                          id="id-I19043644_18532098"
                        >
                          <div
                            className="heading-1-1014"
                            id="id-I19043644_18532099"
                          >
                            <span className="heading-1-1014-0">{"Makeup"}</span>
                          </div>
                          <div
                            className="icon-wrap-1-2397"
                            id="id-I19043644_18532100"
                          >
                            <div
                              className="arrowupright-1-308"
                              id="id-I19043644_18532101"
                            >
                              <div
                                className="icon-1-522"
                                id="id-I19043644_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043644_18532101_3463404516"
                                  id="id-bg-I19043644_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-post-card-3-1200" id="id-19043645">
                    <div
                      className="buttonsbutton-1-1400"
                      id="id-I19043645_18532147"
                    >
                      <div
                        className="text-padding-1-330"
                        id="id-I19043645_18532147_6421273911"
                      >
                        <div
                          className="text-1-2430"
                          id="id-I19043645_18532147_3287432949"
                        >
                          <span className="text-1-2430-0">{"Add to Cart"}</span>
                        </div>
                      </div>
                    </div>
                    <div className="image-1-4380" id="id-I19043645_18532085">
                      <div
                        className="nodeBg-I19043645_18532085"
                        id="id-bg-I19043645_18532085"
                      >
                        {" "}
                      </div>
                      <div className="cart-1-672" id="id-I19043645_2345684">
                        <div
                          className="icon--shoppingo-1-1377"
                          id="id-I19043645_2345684_2345677"
                        >
                          <div
                            className="vector-1-608"
                            id="id-I19043645_2345684_2345677_351646"
                          >
                            <div
                              className="nodeBg-I19043645_2345684_2345677_351646"
                              id="id-bg-I19043645_2345684_2345677_351646"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="rate-1-992" id="id-I19043645_18532086">
                        <div className="text-1-2440" id="id-I19043645_18532094">
                          <div
                            className="this-is-a-text-1-5"
                            id="id-I19043645_18532094_123344529"
                          >
                            <span className="this-is-a-text-1-5-0">
                              {"16 reviews"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="frame-5-1-1826"
                          id="id-I19043645_18532087"
                        >
                          <div className="text-1-89" id="id-I19043645_18532093">
                            <div
                              className="this-is-a-text-1-4050"
                              id="id-I19043645_18532093_123344529"
                            >
                              <span className="this-is-a-text-1-4050-0">
                                {"4.5"}
                              </span>
                            </div>
                          </div>
                          <div
                            className="rate-star-1-3599"
                            id="id-I19043645_18532088"
                          >
                            <div
                              className="icon--starfille-1-4702"
                              id="id-I19043645_18532088_39611242"
                            >
                              <div
                                className="vector-1-300"
                                id="id-I19043645_18532088_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043645_18532088_39611242_35172"
                                  id="id-bg-I19043645_18532088_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-2-740"
                            id="id-I19043645_18532089"
                          >
                            <div
                              className="icon--starfille-1-18"
                              id="id-I19043645_18532089_39611242"
                            >
                              <div
                                className="vector-1-2328"
                                id="id-I19043645_18532089_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043645_18532089_39611242_35172"
                                  id="id-bg-I19043645_18532089_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-3-3780"
                            id="id-I19043645_18532090"
                          >
                            <div
                              className="icon--starfille-1-2726"
                              id="id-I19043645_18532090_39611242"
                            >
                              <div
                                className="vector-1-640"
                                id="id-I19043645_18532090_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043645_18532090_39611242_35172"
                                  id="id-bg-I19043645_18532090_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-4-2728"
                            id="id-I19043645_18532091"
                          >
                            <div
                              className="icon--starfille-1-340"
                              id="id-I19043645_18532091_39611242"
                            >
                              <div
                                className="vector-1-2204"
                                id="id-I19043645_18532091_39611242_35172"
                              >
                                <div
                                  className="nodeBg-I19043645_18532091_39611242_35172"
                                  id="id-bg-I19043645_18532091_39611242_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rate-star-5-785"
                            id="id-I19043645_18532092"
                          >
                            <div
                              className="mask-group-1-4453"
                              id="id-I19043645_18532092_39611239"
                            >
                              <div
                                className="nodeBg-I19043645_18532092_39611239"
                                id="id-bg-I19043645_18532092_39611239"
                              >
                                {" "}
                              </div>
                            </div>
                            <div
                              className="icon--starfille-1-592"
                              id="id-I19043645_18532092_39611244"
                            >
                              <div
                                className="vector-1-928"
                                id="id-I19043645_18532092_39611244_35172"
                              >
                                <div
                                  className="nodeBg-I19043645_18532092_39611244_35172"
                                  id="id-bg-I19043645_18532092_39611244_35172"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="content-1-1916" id="id-I19043645_18532095">
                      <div
                        className="heading-and-tex-1-182"
                        id="id-I19043645_18532096"
                      >
                        <div
                          className="olivia-rhye--20-1-3510"
                          id="id-I19043645_18532097"
                        >
                          <span className="olivia-rhye--20-1-3510-0">
                            {"500 Guests "}&nbsp;{""} <br /> &nbsp;
                            {"Br. 300k for 2 "}&nbsp;{""}
                          </span>
                        </div>
                        <div
                          className="supporting-text-1-1054"
                          id="id-I19043645_18532102"
                        >
                          <span className="supporting-text-1-1054-0">
                            {
                              "How do you create compelling presentations that wow your colleagues and impress your managers?"
                            }
                          </span>
                        </div>
                        <div
                          className="heading-and-ico-1-704"
                          id="id-I19043645_18532098"
                        >
                          <div
                            className="heading-1-819"
                            id="id-I19043645_18532099"
                          >
                            <span className="heading-1-819-0">{"Rings"}</span>
                          </div>
                          <div
                            className="icon-wrap-1-1278"
                            id="id-I19043645_18532100"
                          >
                            <div
                              className="arrowupright-1-2016"
                              id="id-I19043645_18532101"
                            >
                              <div
                                className="icon-1-1224"
                                id="id-I19043645_18532101_3463404516"
                              >
                                <div
                                  className="nodeBg-I19043645_18532101_3463404516"
                                  id="id-bg-I19043645_18532101_3463404516"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="heading-and-sup-1-278" id="id-19043622">
                <div className="supporting-text-1-2052" id="id-19043635">
                  <span className="supporting-text-1-2052-0">
                    {
                      "Tool and strategies modern teams need to help their companies grow."
                    }
                  </span>
                </div>
                <div className="heading-and-sub-1-4092" id="id-19043623">
                  <div className="subheading-1-510" id="id-19043624">
                    <span className="subheading-1-510-0">{"Our blog"}</span>
                  </div>
                  <div className="frame-13-1-759" id="id-19043625">
                    <div className="heading-and-ico-1-1675" id="id-19043626">
                      <div className="frame-14-1-2301" id="id-19043627">
                        <div className="heading-1-4209" id="id-19043630">
                          <span className="heading-1-4209-0">
                            {"Recommended services around your budget"}
                          </span>
                        </div>
                        <div className="icon-wrap-1-530" id="id-19043628">
                          <div
                            className="chevronleftdoub-1-4108"
                            id="id-19043629"
                          >
                            <div
                              className="icon-1-1104"
                              id="id-I19043629_3463404543"
                            >
                              <div
                                className="nodeBg-I19043629_3463404543"
                                id="id-bg-I19043629_3463404543"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="email-capture-1-3362" id="id-19043631">
                        <div className="input-field-1-3040" id="id-19043632">
                          <div
                            className="input-with-labe-1-3168"
                            id="id-I19043632_3285380141"
                          >
                            <div
                              className="input-1-4648"
                              id="id-I19043632_3285380143"
                            >
                              <div
                                className="content-1-2436"
                                id="id-I19043632_3285380144"
                              >
                                <div
                                  className="text-input-1-4818"
                                  id="id-I19043632_3285380145"
                                >
                                  <div
                                    className="leading-text-1-561"
                                    id="id-I19043632_3285380146"
                                  >
                                    <span className="leading-text-1-561-0">
                                      {"$"}
                                    </span>
                                  </div>
                                  <div
                                    className="text-1-539"
                                    id="id-I19043632_3285380147"
                                  >
                                    <span className="text-1-539-0">
                                      {"200k - 300k"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="buttonsbutton-1-3285" id="id-19043634">
                          <div
                            className="icon--searchout-1-392"
                            id="id-I19043634_3471597214"
                          >
                            <div
                              className="vector-1-500"
                              id="id-I19043634_3471597214_3231752"
                            >
                              <div
                                className="nodeBg-I19043634_3471597214_3231752"
                                id="id-bg-I19043634_3471597214_3231752"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="input-field-2-3936" id="id-19043633">
                          <div
                            className="input-with-labe-1-952"
                            id="id-I19043633_3285380141"
                          >
                            <div
                              className="input-1-4550"
                              id="id-I19043633_3285380143"
                            >
                              <div
                                className="content-1-229"
                                id="id-I19043633_3285380144"
                              >
                                <div
                                  className="text-input-1-686"
                                  id="id-I19043633_3285380145"
                                >
                                  <div
                                    className="text-1-608"
                                    id="id-I19043633_3285380147"
                                  >
                                    <span className="text-1-608-0">
                                      {"10 selected"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="dropdown-1-2832"
                                id="id-I19043633_3285380149"
                              >
                                <div
                                  className="chevrondown-1-4560"
                                  id="id-I19043633_3285380151"
                                >
                                  <div
                                    className="icon-1-3080"
                                    id="id-I19043633_3285380151_3463404534"
                                  >
                                    <div
                                      className="nodeBg-I19043633_3285380151_3463404534"
                                      id="id-bg-I19043633_3285380151_3463404534"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTwo;
