import * as React from 'react';
import {register} from '../api/user'
import { useNavigate } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
import sheraton from "../../../Assets/images/Footer1.png"
import button from '../../../Assets/icon/Button.png';
import { Link } from "react-router-dom";
const SecondPage=({clickNextPage, nextPage,fullname,email,password})=>{
	let navigate = useNavigate(); 
	const tags =['Venue','Cake','Photography','Videography','Beauty','Catering','DJ','Decor','Jewellery','Car','Dress']
	const [organizationName, setOrganizationName]=React.useState(''); 
	const [tagline, setTagline]=React.useState(''); 
	const [industry, setIndustry]=React.useState([]); 
	const handleIndustry=(item)=>{
			if(!industry.includes(item)){  
    		setIndustry([...industry,item])  
			}else{
				const temp=[...industry]
				temp.splice(temp.indexOf(item), 1)
    		setIndustry([...temp]); 
				}
	}
	const registerUser=async()=>{
  const user={
     fullName:fullname,
     email:email,
     password:password,
     organizationName: organizationName,
     tagline: tagline,
     userType:'VD001',
     industry: industry.join(','),
  }
  const data = await register(user);
  let path = `/login`; 
    navigate(path);
	}
	return(
		<div className="signup_continer" style={{display:!nextPage?'none':'block'}}>
		<div className="signup_modal">
		<div className="signup_left_modal">
		<img src={logo} className="vendor_logo__header" alt="logo" />
		<div className="page">2 of 2</div>
		<div className="signup_form">
		<div className="form_title">
		Basic Information
		</div>
		<div className="input_fields">
		<span>Organization name</span>
		<input type="text" 
		value={organizationName}
    onChange={(e)=>setOrganizationName(e.target.value)}
    className="input_field"/>
		</div>
		<div className="input_fields">
		<span>Tagline</span>
		<textarea rows="3" 
		value={tagline}
    onChange={(e)=>setTagline(e.target.value)}
    className="textarea_field"></textarea>
		</div>
		<div className="input_fields">
		<span>Industry</span>
		 <div className="categories-su">
                   {tags.map((item)=>(
                     <div onClick={()=>handleIndustry(item)} 
                     style={{background:!industry.includes(item)?'#ddd3b3':'#8f250cff'}} 
                     className="badge-1-su">
                      <div className="text-1-1873">
                        <span 
                        style={{color:!industry.includes(item)?'black':'white'}}
                        className="text-1-1873-su">{item}</span>
                      </div>
                    </div>
                    ))}
                  </div>
		</div>
		<div className="buttons">
		<img src={button} onClick={clickNextPage} className="notification__header back_button" alt="logo" />
		<div className="signup_button second_page_button" onClick={registerUser}>Submit</div>
		</div>
		</div>
		</div>
		<div className="signup_right_modal">
		<div className="image-1-19-su">
           <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${sheraton})` }}>
                        {" "}
            </div>
        </div>
		</div>
		</div>
		</div>
		)
}
export default SecondPage