import * as React from 'react';
import logo from '../../Assets/logo/Tilosh.png';
import sheraton from "../../Assets/images/image6.png"
import { useStateValue } from '../../stores/StateProvider';
import {register} from '../VendorSignUp/api/user'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const BgSignUp=()=>{
	let navigate = useNavigate(); 
// const [{},dispatch]=useStateValue()
	const [nextPage, isNextPage]=React.useState(false); 
	const [fullname, setFullName]=React.useState(''); 
	const [email, setEmail]=React.useState(''); 
	const [password, setPassword]=React.useState(''); 

	const registerUser=async()=>{
  const user={
     fullName:fullname,
     email:email,
     password:password,
     userType:'BG001'
  }
  const data = await register(user);
  let path = `/login`; 
    navigate(path);
	}
	return(
		<>
		<div className="signup_continer" style={{display:nextPage?'none':'block'}}>
		<div className="signup_modal">
		<div className="signup_left_modal">
		<img src={logo} className="vendor_logo__header" alt="logo" />
		<div className="page">1 of 2</div>
		<div className="signup_form">
		<div className="form_title">
		Create Vendor Account
		</div>
		<div className="input_fields">
		<span>Full name</span>
		<input type="text" 
		value={fullname}
    onChange={(e)=>setFullName(e.target.value)}
    className="input_field"/>
		</div>
		<div className="input_fields">
		<span>Email</span>
		<input type="email"
		value={email}
    onChange={(e)=>setEmail(e.target.value)}
    className="input_field"/>
		</div>
		<div className="input_fields">
		<span>Password</span>
		<input type="password" 
		value={password}
    onChange={(e)=>setPassword(e.target.value)}
    className="input_field"/>
		</div>
		<div onClick={registerUser} className="signup_button">SignUp</div>
		</div>
		<div className="signup_login">Already have account? Login</div>
		</div>
		<div className="signup_right_modal">
		<div className="image-1-19-su">
           <div className="nodeBg-I19043604_18532085"
                      style={{backgroundImage: `url(${sheraton})` }}>
                        {" "}
            </div>
        </div>
		</div>
		</div>
		</div>
		</>
		)
}
export default BgSignUp