import React from "react";
import { Link } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
const Header =()=>{
	return (
		<>
		<div className="Header">
		<div className="left__header">
		<Link to="/" className="header_link"><img src={logo} className="logo__header" alt="logo" /></Link>
		<Link to="/vendor" className="header_link"><div className="menu__left">Vendor</div></Link>
    <Link to="/about" className="header_link"><div className="menu__left">About</div></Link>
		</div>
		<div className="right__header">
		<Link to="/signup" className="header_link"><div className="menu__right vendor">Are you a Vendor?</div></Link>
		<Link to="/login" className="header_link"><div className="menu__right">Login</div></Link>
		<Link to="/bg/signup" className="header_link"><div className="menu__right menu__button">
		<span className="menu__text">
                          {"Get Started Now!"}
        </span>
        </div></Link>

	 <Link to="/am" className="header_link">	<div className="menu__right menu__lang">
    
            <div className="translate">  
            <div className="translate__icon">
            </div></div>
             <span className="menu__right">EN</span>
             </div></Link>
		</div>
		</div>
		<div className="container">
		 <div className="group-1-5">
              <div className="vector-1-1584">
                <div className="nodeBg-I19043415_1762592"></div>
              </div>
              <div className="vector-2-530">
                <div className="nodeBg-I19043415_1762593"></div>
              </div>
            </div>

            <div className="group-2-3416">
              <div className="vector-1-3234">
                <div className="nodeBg-I19043415_1765440"></div>
              </div>
              <div className="vector-2-3783">
                <div className="nodeBg-I19043415_1765441"></div>
              </div>
            </div>
            <div className="group-3-2303">
              <div className="vector-1-540">
                <div className="nodeBg-I19043415_1765449"></div>
              </div>
              <div className="vector-2-3612">
                <div className="nodeBg-I19043415_1765450"></div>
              </div>
            </div>
            <div className="group-4-1638">
              <div className="vector-1-851">
                <div className="nodeBg-I19043415_1765443"></div>
              </div>
              <div className="vector-2-216">
                <div className="nodeBg-I19043415_1765444"></div>
              </div>
            </div>
          <div className="frame-vd">
              <div className="content-1-vd">
                <div className="frame-10">
                  <div className="heading-1">
                    <span className="heading-1-2856-0">
                      {"Vendors in sheger "}
                    </span>
                  </div>
                </div>
                      <div className="email-capture">
                        <div className="input-field-1">
                            <div className="input-1-14">
                                <div className="text-input-1-8">
                                    <span className="leading-text-1-2808-0">
                                      {"$"}
                                    </span>
                                    <span className="text-1-792-0">
                                      {"200k - 300k"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="buttonsbutton-1-3526">
                         <Link to="/service" className="header_link">
                      <div className="text-1-h">
                        <span className="text-1-4620-0">{"Change Your Budget"}</span>
                      </div>
                      </Link>
                    </div>
                      </div>
                  </div>
                  </div>
		</div>
		</>
		)
}
export default Header;