import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from '../../../Assets/logo/Tilosh.png';
import notfication from '../../../Assets/icon/Icon1.png';
import user from '../../../Assets/icon/ShoppingOutlined.png';
import { getCarts } from '../api/Bg'
const Header=()=>{
 const cUser=JSON.parse(localStorage.getItem('Tuser'))
 const [cart, setCart]=useState([])
 const loadCarts = async() => {
    const id=cUser['id']
    const user={userId:id}
    const result = await getCarts(user);
    setCart(result['data']['cart']);
}
useEffect(() => {
  loadCarts();
  }, []);
	return(
		<>
		    <div className="Header">
		    <div className="left__header">
		    <Link to="/bg" className="header_link"><img src={logo} className="logo__header" alt="logo" /></Link>
		    <Link to="/bg" className="header_link"><div className="menu__left">Home</div></Link>
		    <Link to="/bg/vendor" className="header_link"><div className="menu__left">Vendors</div></Link>
		    <Link to="/bg/chat" className="header_link"><div className="menu__left">Chat</div></Link>
		    <Link to="/bg/about" className="header_link"><div className="menu__left">About Us</div></Link>
		    </div>
		    <div className="right__header">
		    <Link to="/" className="header_link"><img src={notfication} className="notification__header" alt="logo" /></Link>
		    <Link to="/" className="header_link cart_div"><img src={user} className="notification__header" alt="logo" />
		    <span className="cart_badge">{cart?.length}</span>
		    </Link>

		   <Link to="/am" className="header_link">  <div className="menu__right menu__lang">
		    
		            <div className="translate">  
		            <div className="translate__icon">
		            </div></div>
		             <span className="menu__right">EN</span>
		             </div></Link>

		    <Link to="/login" className="header_link"><div className="menu__right">Logout</div></Link>
		    </div>
		    </div>
		</>
		)
}
export default Header