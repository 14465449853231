import { axios } from '../../../lib/axios';

export const register = (data) => {
  return axios.post('/users', {
      ...data
  },{
        headers: {
          "Content-Type": "multipart/form-data",
          "x-rapidapi-host": "file-upload8.p.rapidapi.com",
          "x-rapidapi-key": "your-rapidapi-key-here",
        },
      });
};

export const login = (data) => {
  return axios.post('/users/login', {
      ...data
  });
};


export const updateUser = (data) => {
  return axios.put('/users', {
    user: {
      ...data
    }
  });
};
