import { axios } from '../../../lib/axios';


export const cart = (data) => {
  return axios.post('/carts', {
      ...data
  });
};


export const productList = (data) => {
  return axios.post('/products/category', {
      ...data
  });
};

export const packages = (data) => {
  return axios.post('/packages/user', {
      ...data
  });
};

