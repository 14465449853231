import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./Features/Home/Home";
import Vendor from "./Features/Vendor/Vendor";
import LoggedInVendor from './Features/Vendor/LoggedInVendor'
import VendorHome from './Features/Vendor/VendorHome'
import About from "./Features/About/About"
import Venue from "./Features/Venue/Venue"
import Chat from "./Features/Chat/Chat"
import Portfolio from "./Features/Portfolio/Portfolio"
import ViewPortfolio from "./Features/Portfolio/ViewPortfolio"
import SignUp from "./Features/VendorSignUp/SignUp"
import SecondPage from "./Features/VendorSignUp/components/SecondPage"
import Login from "./Features/VendorSignUp/components/Login"
import BGHome from "./Features/BG/Home"
import BGChat from "./Features/BG/Chat/Chat"
import BGVendor from "./Features/BG/Vendors"
import EventBudget from "./Features/BG/EventBudget"
import BgSignUp from "./Features/BG/BgSignUp"
import HomeAm from "./Features/HomeAm/HomeAm"
import Services from "./Features/Services/Services"
import EnHome from "./Pages/Home/Home";
import AmharicHome from "./Pages/AmharicHome/AmharicHome";
import Service from "./Pages/Service/Service";
import ServiceTwo from "./Pages/ServiceTwo/ServiceTwo";
import "./App.css";
const App = () => {
  return (
    <div className="App"> 
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/en" element={<EnHome/>} />
      <Route path="/am" element={<HomeAm/>}  />
      <Route path="/venue" element={<Venue/>} />
      <Route path="/vendor" element={<Vendor/>} />
      <Route path="/vendors" element={<LoggedInVendor/>} />
      <Route path="/home" element={<VendorHome/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/portfolio" element={<ViewPortfolio/>} />
      <Route path="/portfolio/edit" element={<Portfolio/>} />
      <Route path="/chat" element={<Chat/>} />
      <Route path="/signup" element={<SignUp/>} />
      <Route path="/signup/2" element={<SecondPage/>} />
      <Route path="/login" element={<Login/>} />
      <Route path="/bg" element={<BGHome/>} />
      <Route path="/bg/chat" element={<BGChat/>} />
      <Route path="/bg/vendor" element={<BGVendor/>} />
      <Route path="/bg/event-budget" element={<EventBudget/>} />
      <Route path="/bg/signup" element={<BgSignUp/>} />
      <Route path="/service" element={<Services/>} />
      <Route path="/services" element={<ServiceTwo/>} />
    </Routes>
  </BrowserRouter>
   
    </div>
  );
};
// ReactDOM.createRoot(document.getElementById("dualite-root")).render(<App />);
export default App;