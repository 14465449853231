import CategorySmallCards from './CategorySmallCards'
import CategoryBigCard from './CategoryBigCard'
import CategoryMedCards from './CategoryMedCards'
import catering from "../../../Assets/images/image11.png"
import venue from "../../../Assets/images/image12.png"
import makeup from "../../../Assets/images/image6.png"
import weddingDress from "../../../Assets/images/image15.png"
import photography from "../../../Assets/images/image7.png"
import videography from "../../../Assets/images/image8.png"
import jewelers from "../../../Assets/images/image3.png"
import carRental from"../../../Assets/images/image4.png"
import cake from "../../../Assets/images/image9.png"
import dj from "../../../Assets/images/image10.png"
const CategoryBlog=()=>{
  const smallCard=[
  {
    title:"የሠርግ ልብስ",
    img:weddingDress
  },
  {
    title:'ሜካአፕ',
    img:makeup
  },
  {
    title:'ፎቶ',
    img:photography
  },
  {
    title:'ቪዲዮ',
    img:videography
  }]
  const midCard=[
  {
    title:'ምግብ አቅርቦት',
    img:catering
  },
  {
    title:'አዳራሽ',
    img:venue
  }]
	return(
		 <div className="blog-section-2-738">
              <div className="heading-and-sup-1-1274">
                  <div className="heading-1-1254">
                    <span className="heading-1-1254-0">
                      {"Vendor Categories"}
                    </span>
                  </div>
          	</div>
          <div className="container-2">
            <div className="content-1-2">
            <CategoryBigCard 
            largeTitle={'ቀለበት'}
            largeImg={jewelers}
            smallTitle={'መኪና ኪራይ'}
            smallImg={carRental}/>
              <div className="small-cards">
              {smallCard.map(x => (
              <CategorySmallCards 
              data={x} />
              ))}
              </div>
            <CategoryBigCard 
            largeTitle={'ኬክ'}
            largeImg={cake}
            smallTitle={'ዲጄ'}
            smallImg={dj}/>
            <div className="medium-cards">
            {midCard.map(x => (
            <CategoryMedCards
            data={x} />
            ))}
              </div>
            </div>
          </div>
          </div>
          ) 
}
export default CategoryBlog