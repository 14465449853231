import React  from "react";
import "../Vendor/Vendor.css";
import Header from "./components/BudgetHeader";
import EventBudgetDetail from "./components/EventBudgetDetail"
const EventBudget=()=>{
	return(
    <>
    <Header />
    <EventBudgetDetail />
    </>
		)
}
export default EventBudget