
const CategoryMedCards=({data})=>{
	return(
              <div className="blog-post-card-9-752">
                <div className="image-1-900">
                  <div className="nodeBg-I19043447_1751433"
                   style={{backgroundImage: `url(${data.img})` }}>
                    {" "}
                  </div>
                </div>
                <div className="content-1-1242">
                        <span className="heading-1-2592-0">{data.title}</span>
                </div>
              </div>
		)
}
export default CategoryMedCards